import React from "react";
import HeaderLogo from "../../assets/images/Vooth_Header_Logo.png";
import cssClasses from "./HeaderLogo.module.css";
import { withRouter } from "react-router";

const headerLogo = (props) => {
    return (
        <div className={cssClasses.HeaderLogo}>
            <img src={HeaderLogo} alt="Vooth Header Logo" onClick={() => props.history.push("./login")}></img>
        </div>
    );
}

export default withRouter(headerLogo);