import React, { Component } from "react";
import { connect } from "react-redux";

import cssClasses from "./Login.module.css";
import Input from "../../../components/UI/Input/Input";
import HeaderLogo from "../../../components/HeaderLogo/HeaderLogo";
// import LoadingCube from "../../../components/UI/LoadingCube/LoadingCube";
import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import AuthError from "../AuthError/AuthError";
import AuthSuccess from "../AuthSuccess/AuthSuccess";

import LoginActions from "./LoginActions/LoginActions";
import * as actions from "../../../store/actions/index";

class Login extends Component {
  state = {
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "",
          label: "Email",
        },
        value: "",
        validation: {
          required: true,
          isEmail: true,
        },
        valid: true,
        touched: false,
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "",
          label: "Password",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 6,
          maxLength: 10,
        },
        valid: true,
        touched: false,
      },
    },
    error: false,
    message: ""
  };

  componentDidUpdate() {
    if (this.props.loginSuccessfull) {
      this.props.history.push("./mainapp");
    }
  }

  componentDidMount() {
    var searchParams = new URLSearchParams(this.props.location.search);

    if (searchParams.get("message")) {
      var message = searchParams.get("message");

      this.props.onAuthError(message);
    }

    // this.props.onResetError();

    if (this.props.error) {
      this.setState({ controls: { ...this.state.controls }, error: true, message: this.props.message })
    }

    if (localStorage.getItem("idToken")) {
      this.props.history.push("./mainapp");
    }
  }

  checkValidity(value, rules) {
    let isValid = true;

    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase());
    }

    return isValid;
  }

  inputChangeHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        touched: true,
      },
    };
    this.setState({ controls: updatedControls });
  }

  inputValidatingHandler = (controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        valid: this.checkValidity(
          this.state.controls[controlName].value,
          this.state.controls[controlName].validation
        ),
        touched: true,
      },
    };
    this.setState({ controls: updatedControls });
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (this.state.controls.email.valid && this.state.controls.password.valid) {
      this.props.onLogin(
        this.state.controls.email.value,
        this.state.controls.password.value
      );
    } else if (!this.state.controls.email.valid) {
      this.props.onAuthError("Email is invalid");
    } else {
      this.props.onAuthError("Password is invalid");
    }
  }

  render() {

    // if (localStorage.getItem("idToken")) {
    //   this.props.history.push("./mainapp");
    //   return;
    // }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    let form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangeHandler(event, formElement.id)}
        label={formElement.config.elementConfig.label}
        blur={() => this.inputValidatingHandler(formElement.id)}
      ></Input>
    ));

    let loginForm = <form id="login-form" className={cssClasses.Login} onSubmit={this.submitHandler}>
      <h1
        style={{
          color: "#000000",
          margin: "0 0 1.98% 0",
          font: "normal normal bold 24px/28px Arial",
        }}
      >
        Log in
      </h1>
      {form}
      <LoginActions />
    </form>;

    if (this.props.loading) {
      loginForm = <div className={cssClasses.Login}><LoadingSpinner /></div>;
    }

    let authErrorComponent = null;
    let authSuccessComponent = null;

    if (this.props.error) {
      authErrorComponent = <AuthError authError={this.props.message} />;
    }

    if (!this.props.error && this.props.message) {
      authSuccessComponent = <AuthSuccess authSuccess={this.props.message} />;
    }


    return (
      <div className={cssClasses.LoginParent}>
        <HeaderLogo />
        {loginForm}
        {authErrorComponent}
        {authSuccessComponent}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    message: state.auth.message,
    loginSuccessfull: state.auth.loginSuccessfull
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (email, password) =>
      dispatch(actions.login(email, password)),
    onAuthError: (error) =>
      dispatch(actions.authError(error)),
    onResetError: () =>
      dispatch(actions.authErrorReset())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
