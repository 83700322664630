import * as actionTypes from "./actionTypes";
import axios from "axios";

export const authChangePassword = () => {
    return {
        type: actionTypes.AUTH_CHANGE_PASSWORD,
        error: false
    }
}

export const authChangePasswordSuccess = () => {
    return {
        type: actionTypes.AUTH_CHANGE_PASSWORD_SUCCESS,
        error: false
    }
}

export const authChangePasswordFail = (message) => {
    return {
        type: actionTypes.AUTH_CHANGE_PASSWORD_FAIL,
        error: false,
        message: message
    }
}

export const changePassword = (oobCode, newPassword) => {

    return (dispatch) => {
        dispatch(authChangePassword());
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/changepassword', {
            oobCode: oobCode,
            newPassword: newPassword
        })
            .then((res) => {
                dispatch(authChangePasswordSuccess());
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(authChangePasswordFail(error.response.data.error));
                }
            })
    }
}