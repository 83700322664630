import React, { Component } from "react";
import { connect } from "react-redux";

// import LoadingCube from "../../../components/UI/LoadingCube/LoadingCube";
import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import AuthError from "../AuthError/AuthError";

import cssClasses from "./AuthAction.module.css";
import * as actions from "../../../store/actions/index";

class AuthAction extends Component {

    componentDidMount() {
        this.saveOobCode(this.props.location.search);
    }

    saveOobCode = (params) => {
        var searchParams = new URLSearchParams(params);
        if (searchParams.get("oobCode")) {
            var updatedOobCode = searchParams.get("oobCode").toString();
            console.log("var oobCode : " + updatedOobCode);
            this.props.onVerifyPasswordReset(updatedOobCode);
        }
    }

    render() {

        let authErrorComponent = null;

        if (this.props.error) {
            authErrorComponent = <AuthError authError={this.props.message} />;
        }

        let loadingComponent = <LoadingSpinner />;

        if (this.props.verifyPasswordSuccessfull) {
            loadingComponent = this.props.history.push("/changepassword");
        }

        return (<div className={cssClasses.AuthAction}>{loadingComponent}<br /><br /><br />{authErrorComponent}</div>);
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        message: state.auth.message,
        verifyPasswordSuccessfull: state.auth.verifyPasswordSuccessfull
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onVerifyPasswordReset: (oobCode) =>
            dispatch(actions.verifyPasswordReset(oobCode)),
        onChangePassword: (updatedPassword) =>
            dispatch(actions.changePassword(updatedPassword)),
        onAuthError: (error) =>
            dispatch(actions.authError(error)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthAction);