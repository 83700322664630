import * as actionTypes from "./actionTypes";

export const enableAnalytics = () => {
    return {
        type: actionTypes.ANALYTICS
    };
};

export const analytics = () => {
    return (dispatch) => {
        dispatch(enableAnalytics());
    }
};  