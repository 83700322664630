import * as actionTypes from "./actionTypes";
import axios from "axios";

export const profileChangePassword = () => {
    return {
        type: actionTypes.PROFILE_CHANGE_PASSWORD,
        error: false
    };
};
export const profileChangePasswordUpdateRequest = () => {
    return {
        type: actionTypes.PROFILE_CHANGE_PASSWORD_UPDATE_REQUEST,
        error: false
    };
};

export const profileChangePasswordSuccess = (idToken, refreshToken, expirationTime, message) => {
    return {
        type: actionTypes.PROFILE_CHANGE_PASSWORD_SUCCESS,
        idToken: idToken,
        refreshToken: refreshToken,
        expirationTime: expirationTime,
        error: false,
        message: message
    };
};

export const profileChangePasswordFail = (message) => {
    return {
        type: actionTypes.PROFILE_CHANGE_PASSWORD_FAIL,
        error: true,
        message: message
    };
};

export const changePasswordProfile = (password) => {
    return (dispatch) => {
        dispatch(profileChangePasswordUpdateRequest());
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/changeprofilepassword', {
            idToken: localStorage.getItem("idToken").replace(/['"]+/g, ''),
            password: password
        })
            .then((res) => {
                localStorage.setItem("idToken", JSON.stringify(res.data.res.idToken));
                localStorage.setItem("refreshToken", JSON.stringify(res.data.res.refreshToken));

                var expirationTime = Number(localStorage.getItem("expirationTime").replace(/['"]+/g, '')) + Number(res.data.res.expiresIn);
                localStorage.setItem("expirationTime", JSON.stringify(expirationTime));

                dispatch(profileChangePasswordSuccess(res.data.res.idToken, res.data.res.refreshToken, expirationTime, "Password changed successfully!"));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERR: " + error.response.data.error);
                    dispatch(profileChangePasswordFail(error.response.data.error));
                }
            })
    }
}