import React from "react";
import cssClasses from "./Toolbar.module.css";
import Logo from "../../../Logo/Logo";
import UserCompanyName from "../../../UserCompanyName/UserCompanyName";
import NavigationItems from "../NavigationItems";

const toolbar = (props) => {
    return (
        <div className={cssClasses.Toolbar}>
            <Logo />
            <UserCompanyName companyName={props.company} />
            <NavigationItems />
        </div>
    );
}

export default toolbar;