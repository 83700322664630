import React, { Component } from "react";
import { connect } from "react-redux";

import cssClasses from "./SaasTool.module.css";
import Header from "./Header/Header";
import BoothCreator from "./BoothCreator/BoothCreator";
import RoomCreator from "./RoomCreator/RoomCreator";
import Meetings from "./Meetings/Meetings";
import Analytics from "./Analytics/Analytics";

import ScheduleMeetingModal from "../UI/Modals/ScheduleMeetingModal/ScheduleMeetingModal";
import UpdateMeetingModal from "../UI/Modals/UpdateMeetingModal/UpdateMeetingModal";
import * as actions from "../../store/actions/index";

class SaasTool extends Component {

  scheduleMeetingToggler = () => {
    this.props.onScheduleMeetingBox(!this.props.scheduleMeetingBox);
  }

  updateMeetingToggler = () => {
    this.props.onUpdateMeetingBox(!this.props.updateMeetingBox);
  }

  componentDidMount() {
    this.props.onScheduleMeetingBox(false);
  }

  render() {
    let tool = null;
    let meetingComponent = null;

    switch (this.props.appState) {

      case "boothCreator": {
        tool = <BoothCreator />
        break;
      }

      case "roomCreator": {
        tool = <RoomCreator />;
        break;
      }

      case "meetings": {
        tool = <Meetings />;
        break;
      }

      case "analytics": {
        tool = <Analytics />;
        break;
      }

      default: {
        tool = null;
        break;
      }
    }

    if (this.props.scheduleMeetingBox) {
      meetingComponent = <ScheduleMeetingModal show={true} modalClosed={this.scheduleMeetingToggler} />
    }

    if (this.props.updateMeetingBox) {
      meetingComponent = <UpdateMeetingModal show={true} modalClosed={this.updateMeetingToggler} meeting={this.props.updateMeeting} />
    }

    return (
      <div className={cssClasses.SaasToolParent}>
        <div className={cssClasses.SaasTool}>
          <Header saasToolName={this.props.saasToolName} name={this.props.name} clicked={this.scheduleMeetingToggler} />
          <hr style={{ width: "92.15%", borderTop:"2px solid #843D96", margin: "0 0 0 0 " }} />
          {tool}
        </div>
        {meetingComponent}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.mainApp.appState,
    saasToolName: state.mainApp.saasToolName,
    scheduleMeetingBox: state.mainApp.scheduleMeetingBox,
    updateMeetingBox: state.mainApp.updateMeetingBox,
    scheduleMeeting: state.mainApp.scheduleMeeting,
    updateMeeting: state.mainApp.updateMeeting
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUserDetails: () =>
      dispatch(actions.getUserDetails()),
    onLogout: () =>
      dispatch(actions.logout()),
    onScheduleMeetingBox: (value) =>
      dispatch(actions.scheduleMeetingBox(value)),
    onUpdateMeetingBox: (value) =>
      dispatch(actions.updateMeetingBox(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaasTool);
