import React, { Component } from "react";
import { connect } from "react-redux";

import cssClasses from "./ProfileDashboard.module.css";
import InputEditable from "../../../components/UI/InputEditable/InputEditable";
import HeaderLogo from "../../../components/HeaderLogo/HeaderLogo";
// import LoadingCube from "../../../components/UI/LoadingCube/LoadingCube";
import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import AuthError from "../AuthError/AuthError";
import AuthSuccess from "../AuthSuccess/AuthSuccess";
import ProfileActions from "../ProfileDashboard/ProfileActions/ProfileActions";

import * as actions from "../../../store/actions/index";

class ProfileDashboard extends Component {
  state = {
    controls: {
      name: {
        elementType: "input",
        elementConfig: {
          type: "name",
          placeholder: "",
          label: "Your Name*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 3,
          maxLength: 10,
        },
        isEditable: true,
        valid: false,
        touched: false,
      },
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "",
          label: "Email*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: true,
        },
        isEditable: true,
        valid: false,
        touched: false,
      },
      company: {
        elementType: "input",
        elementConfig: {
          type: "company",
          placeholder: "",
          label: "Company*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 3,
          maxLength: 20,
        },
        isEditable: true,
        valid: false,
        touched: false,
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "",
          label: "Password*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 3,
          maxLength: 20,
        },
        isEditable: true,
        valid: false,
        touched: false,
      },
      newpassword: {
        elementType: "input",
        elementConfig: {
          type: "newpassword",
          placeholder: "",
          label: "New Password*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 6,
          maxLength: 10,
        },
        isEditable: true,
        valid: false,
        touched: false,
      },
      confirmpassword: {
        elementType: "input",
        elementConfig: {
          type: "confirmpassword",
          placeholder: "",
          label: "Confirm Password*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 6,
          maxLength: 10,
          isConfirmPassword: true
        },
        valid: false,
        touched: false,
      },
    },
    avatarAppearance: "",
    passwordEditing: false,
    inputSelected: false
  };

  componentDidMount = () => {
    if (this.props.userDetails === null) {
      this.props.history.push("./mainApp");
    }
    else {
      const updatedControls = {
        ...this.state.controls,
        name: {
          ...this.state.controls.name,
          value: this.props.userDetails.name,
          valid: true,
        },
        email: {
          ...this.state.controls.email,
          value: this.props.userDetails.email,
          valid: true,
        },
        company: {
          ...this.state.controls.company,
          value: this.props.userDetails.company,
          valid: true,
        },
      }

      const newAvatarApperance = this.props.userDetails.avatarappearance;

      this.setState({ controls: updatedControls, avatarAppearance: newAvatarApperance });
    }
  }

  checkValidity(value, rules) {
    let isValid = true;

    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase());
    }

    if (rules.isConfirmPassword) {
      isValid = value === this.state.controls.newpassword.value;
    }

    return isValid;
  }

  // inputChangeHandler = (event, controlName) => {
  //   const updatedControls = {
  //     ...this.state.controls,
  //     [controlName]: {
  //       ...this.state.controls[controlName],
  //       value: event.target.value,
  //       valid: this.checkValidity(
  //         event.target.value,
  //         this.state.controls[controlName].validation
  //       ),
  //       touched: true
  //     }
  //   }
  //   this.setState({ controls: updatedControls });
  // }

  avatarAppearanceSelectionHandler = (avatarAppearance) => {

    let oldAvatarAppearance = {
      ...this.state.avatarAppearance
    }

    oldAvatarAppearance = avatarAppearance;

    this.setState({ avatarAppearance: oldAvatarAppearance });
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (this.state.controls.name.valid && this.state.controls.email.valid && this.state.avatarAppearance !== undefined && this.state.avatarAppearance.length) {
      let body = {
        name: this.state.controls.name.value,
        email: this.state.controls.email.value,
        company: this.state.controls.company.value,
        avatarappearance: this.state.avatarAppearance
      }

      this.props.onUpdateUserDetails(body);

    } else if (!this.state.controls.name.valid) {
      this.props.onAuthError("Name is invalid");
    } else if (!this.state.controls.email.valid) {
      this.props.onAuthError("Email is invalid");
    } else if (!this.state.controls.company.valid) {
      this.props.onAuthError("Company is invalid");
    } else if (this.state.avatarAppearance !== undefined && this.state.avatarAppearance.length) {
      this.props.onAuthError("Avatar appearance is invalid");
    }
  }

  logInputSelectionValue = () => {
    this.setState({ inputSelected: !this.state.inputSelected });
    console.log("inputSelected " + this.state.inputSelected);
  }

  // New handlers

  textInputChangeHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        touched: true
      }
    }
    this.setState({ controls: updatedControls });
  }

  textInputValidityHandler = (controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        valid: this.checkValidity(
          this.state.controls[controlName].value,
          this.state.controls[controlName].validation
        ),
        touched: true
      }
    }
    this.setState({ controls: updatedControls });
  }

  emailChanged = () => {
    console.log("email is changed");
    if (this.state.controls.email.valid) {
      console.log("email is changed and valid");
      this.props.onChangeEmailProfile(this.state.controls.email.value)
    }
  }

  passwordChanged = () => {
    console.log("password is changed");

    if (this.state.controls.confirmpassword.valid) {
      this.props.onChangePasswordProfile(this.state.controls.confirmpassword.value)
    }
  }

  render() {

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    let passwordComponent = <InputEditable
      key="password"
      elementType={this.state.controls.password.elementType}
      elementConfig={this.state.controls.password.elementConfig}
      value={this.state.controls.password.value}
      invalid={!this.state.controls.password.valid}
      shouldValidate={this.state.controls.password.validation}
      touched={this.state.controls.password.touched}
      changed={(event) => this.textInputChangeHandler(event, "password")}
      label={this.state.controls.password.elementConfig.label}
      editable={this.state.controls.password.isEditable} />

    console.log("this.props.passwordUpdating: " + this.props.passwordUpdating);

    if (this.props.passwordUpdating) {
      passwordComponent = <div>
        <InputEditable
          key="newpassword"
          elementType={this.state.controls.newpassword.elementType}
          elementConfig={this.state.controls.newpassword.elementConfig}
          value={this.state.controls.newpassword.value}
          invalid={!this.state.controls.newpassword.valid}
          shouldValidate={this.state.controls.newpassword.validation}
          touched={this.state.controls.newpassword.touched}
          changed={(event) => this.textInputChangeHandler(event, "newpassword")}
          label={this.state.controls.newpassword.elementConfig.label}
          editable={this.state.controls.newpassword.isEditable}
          blur={() => this.textInputValidityHandler("newpassword")} />

        <InputEditable
          key="confirmpassword"
          elementType={this.state.controls.confirmpassword.elementType}
          elementConfig={this.state.controls.confirmpassword.elementConfig}
          value={this.state.controls.confirmpassword.value}
          invalid={!this.state.controls.confirmpassword.valid}
          shouldValidate={this.state.controls.confirmpassword.validation}
          touched={this.state.controls.confirmpassword.touched}
          changed={(event) => this.textInputChangeHandler(event, "confirmpassword")}
          label={this.state.controls.confirmpassword.elementConfig.label}
          editable={this.state.controls.confirmpassword.isEditable}
          blur={(event) => this.passwordChanged(event)} />
      </div>
    }

    let formElement = <div style={{ margin: "0 0 0 2% " }}>
      <InputEditable
        key="name"
        elementType={this.state.controls.name.elementType}
        elementConfig={this.state.controls.name.elementConfig}
        value={this.state.controls.name.value}
        invalid={!this.state.controls.name.valid}
        shouldValidate={this.state.controls.name.validation}
        touched={this.state.controls.name.touched}
        changed={(event) => this.textInputChangeHandler(event, "name")}
        label={this.state.controls.name.elementConfig.label}
        editable={this.state.controls.name.isEditable}
        blur={() => this.textInputValidityHandler("name")} />
      <InputEditable
        key="email"
        elementType={this.state.controls.email.elementType}
        elementConfig={this.state.controls.email.elementConfig}
        value={this.state.controls.email.value}
        invalid={!this.state.controls.email.valid}
        shouldValidate={this.state.controls.email.validation}
        touched={this.state.controls.email.touched}
        changed={(event) => this.textInputChangeHandler(event, "email")}
        label={this.state.controls.email.elementConfig.label}
        editable={this.state.controls.email.isEditable}
        blur={(event) => this.emailChanged(event)}
        blur={() => this.textInputValidityHandler("email")} />
      <InputEditable
        key="company"
        elementType={this.state.controls.company.elementType}
        elementConfig={this.state.controls.company.elementConfig}
        value={this.state.controls.company.value}
        invalid={!this.state.controls.company.valid}
        shouldValidate={this.state.controls.company.validation}
        touched={this.state.controls.company.touched}
        changed={(event) => this.textInputChangeHandler(event, "company")}
        label={this.state.controls.company.elementConfig.label}
        editable={this.state.controls.company.isEditable}
        blur={() => this.textInputValidityHandler("company")} />
      {passwordComponent}
      <ProfileActions selected={this.avatarAppearanceSelectionHandler} previousAvatarAppearance={this.state.avatarAppearance} buttonName="Submit" />
    </div>

    let registerForm = <form id="register-form" className={cssClasses.ProfileDashboard} onSubmit={this.submitHandler}>
      <h1
        style={{
          color: "#000000",
          margin: "0 0 1.98% 0",
          font: "normal normal bold 24px/28px Arial",
        }}
      >
        Profile
      </h1>
      {formElement}
      {/* <ProfileActions selected={this.avatarAppearanceSelectionHandler} previousAvatarAppearance={this.state.avatarAppearance} buttonName="Submit" /> */}
    </form>;

    if (this.props.userDetailsUpdating) {
      // registerForm = <div className={cssClasses.ProfileDashboard}><LoadingSpinner /></div>;
      registerForm = <LoadingSpinner />
    }

    let authErrorComponent = null;
    let authSuccessComponent = null;

    if (this.props.error) {
      authErrorComponent = <AuthError authError={this.props.message} />;
    }

    if (this.props.message && !this.props.error) {
      authSuccessComponent = <AuthSuccess authSuccess={this.props.message} />;
    }

    return (
      <div className={cssClasses.ProfileDashboardParent}>
        <HeaderLogo />
        {registerForm}
        {authErrorComponent}
        {authSuccessComponent}
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    message: state.auth.message,
    passwordUpdating: state.auth.passwordUpdating,
    userDetailsUpdating: state.auth.userDetailsUpdating,
    userDetails: state.mainApp.userDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeEmailProfile: (email) => {
      dispatch(actions.changeEmail(email))
    },
    onChangePasswordProfile: (newPassword) => {
      dispatch(actions.changePasswordProfile(newPassword))
    },
    onUpdateUserDetails: (body) => {
      dispatch(actions.updateUserDetails(body))
    },
    onAuthError: (error) =>
      dispatch(actions.authError(error))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDashboard);
