import React, { Component } from "react";

import cssClasses from "./RoomTile.module.css";
import closeButton from "../../../assets/images/close-button.png";

class RoomTile extends Component {

    state = {
        isHovering: false
    }

    handleMouseHoverEnter = () => {
        this.setState({ isHovering: true });
    }

    handleMouseHoverLeave = () => {
        this.setState({ isHovering: false });
    }

    render() {

        let closeButtonContainer = null;

        if (this.state.isHovering) {
            closeButtonContainer =
                <div style={{ width: "100%", height: "100%", margin: "0 0 0 0", position: "absolute", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <img className={cssClasses.CloseButton} src={closeButton} onClick={this.props.delete}></img>
                </div>
        }

        return (
            <div className={cssClasses.RoomTile} onMouseEnter={this.handleMouseHoverEnter} onMouseLeave={this.handleMouseHoverLeave}>
                <div className={cssClasses.RoomTileHeader}>
                    <p style={{ width: "100%", height: "100%", margin: "0 0 0 0", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {this.props.name}
                    </p>
                    {closeButtonContainer}
                    {/* <img className={cssClasses.CloseButton} src={closeButton} onClick={this.props.delete}></img> */}
                    {/* <div style={{ width: "6.56%", height: "100%", alignSelf: "end" }}>
                        <button style={{ width: "39.65%%", height: "21.92%", border: "none", padding: "0", margin: "67.44% 62.79% 67.44% 0", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={this.props.delete}>
                            <p style={{ backgroundColor: "#0A2F42", color: "#757575", width: "100%", height: "100%", margin: "0 0 0 0", display: "flex", justifyContent: "center", alignItems: "center" }}>x</p>
                        </button>
                    </div> */}
                </div>
                <div className={cssClasses.RoomTileImage} onClick={this.props.clicked}>
                    <img src={this.props.screenshotLink} style={{ height: "100%", width: "100%" }} />
                </div>
            </div>
        );
    }
}

export default RoomTile;