import * as actionTypes from "./actionTypes";

export const enableMeetings = () => {
    return {
        type: actionTypes.MEETINGS
    };
};

export const enableScheduleMeetingBox = () => {
    return {
        type: actionTypes.ENABLE_SCHEDULE_MEETING_BOX
    };
};

export const disableScheduleMeetingBox = () => {
    return {
        type: actionTypes.DISABLE_SCHEDULE_MEETING_BOX
    };
};

export const scheduleMeetingStateReset = () => {
    return {
        type: actionTypes.SCHEDULE_MEETING_STATE_RESET
    };
};

export const enableUpdateMeetingBox = (data) => {
    return {
        type: actionTypes.ENABLE_UPDATE_MEETING_BOX,
        meetingData: data
    };
};

export const disableUpdateMeetingBox = () => {
    return {
        type: actionTypes.DISABLE_UPDATE_MEETING_BOX
    };
};

export const updateMeetingStateReset = () => {
    return {
        type: actionTypes.UPDATE_MEETING_STATE_RESET
    };
};

export const meetings = () => {
    return (dispatch) => {
        dispatch(enableMeetings());
    }
};

export const scheduleMeetingBox = (value) => {
    return (dispatch) => {
        if (value) {
            dispatch(enableScheduleMeetingBox());
        }
        else {
            dispatch(scheduleMeetingStateReset());
            dispatch(disableScheduleMeetingBox());
        }
    }
};

export const updateMeetingBox = (value, data) => {
    return (dispatch) => {
        if (value) {
            dispatch(enableUpdateMeetingBox(data));
        }
        else {
            dispatch(updateMeetingStateReset());
            dispatch(disableUpdateMeetingBox());
        }
    }
};