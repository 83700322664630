import React, { Component } from "react";
import cssClasses from "./RoomCreator.module.css";

import RoomTile from "../RoomTile/RoomTile";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";

class RoomCreator extends Component {
  state = {
    selectionIndex: null
  }

  enableRoomCreator = (selectionIndex) => {
    this.props.onRoomEditing();
    this.setState({ selectionIndex: selectionIndex });
  }

  disableRoomCreator = () => {
    this.props.onUserDetails();
    this.props.onResetEditing();
    this.setState({ selectionIndex: null });
  }

  deleteTile = (index) => {
    if (window.confirm('Are you sure you wish to delete this room?')) {

      let roomsArray = [];
      roomsArray = this.props.rooms;

      // console.log("roomsArray.length: " + roomsArray.length);

      let newRoomsArray = [];

      roomsArray.forEach(element => {
        if (element !== roomsArray[index]) {
          newRoomsArray.push(element);
        }
      });

      // console.log("newRoomsArray.length: " + newRoomsArray.length);

      let body = {
        rooms: newRoomsArray
      }
      this.props.onUpdateUserDetails(body);
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.interactionWithUnity)
  }

  interactionWithUnity = (event) => {
    console.log("Event data recieved in react: " + event.data);

    switch (event.data) {
      case "Loaded": {

        const childFrameObj = document.getElementById('iframeRoom');
        childFrameObj.contentWindow.postMessage(localStorage.getItem("idToken").replace(/['"]+/g, ''), '*'); //window.postMessage
        childFrameObj.contentWindow.postMessage("selectionIndex:" + this.state.selectionIndex, '*'); //window.postMessage

        break;
      }
      case "Save": {
        console.log("Unity asked to Save the iframe");
        if (this.props.roomEditing) {
          if (window.confirm('Are you sure you want to save the room?')) {
            const childFrameObj = document.getElementById('iframeRoom');
            childFrameObj.contentWindow.postMessage("save", '*');
          }
        }
        break;
      }
      case "Close": {
        console.log("Unity asked to close the iframe");
        this.disableRoomCreator();
        break;
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.interactionWithUnity);
  }

  render() {
    console.log("Rooms : " + this.props.rooms);
    const roomsArray = [];
    let roomsListContent = null;

    for (let key in this.props.rooms) {
      roomsArray.push({
        id: key,
        data: this.props.rooms[key],
      });
    }

    roomsListContent = roomsArray.map((room, index) => (
      <RoomTile
        key={room.data.id}
        clicked={() => this.enableRoomCreator(index)}
        delete={() => this.deleteTile(index)}
        name={room.data.name}
        screenshotLink={room.data.screenshotLink}
      />
    ));

    let roomsList = <div className={cssClasses.RoomsListPanel}>
      <div className={cssClasses.NewRoomTile} onClick={() => this.enableRoomCreator(null)}>
        <p style={{ font: "normal normal bold 18px/21px Arial", color: "#000000" }}>Create New Room</p>
      </div>
      {roomsListContent}
    </div>;

    let frame = null;

    if (this.props.roomEditing) {
      frame = <iframe id="iframeRoom" title="RoomCreator" src="https://rc.d30mhpuq0v2sfd.amplifyapp.com/" style={{height:"100%",width:"100%", display: "flex",justifyContent:"center",alignItems:"center",alignContent:"center", margin: "0 0 0 0" }} frameBorder="0" scrolling="no"></iframe>
    }
    else {
      frame = roomsList
    }

    return (< div className={cssClasses.RoomCreator} >
      {frame}
    </div >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    rooms: state.mainApp.userDetails.rooms,
    roomEditing: state.mainApp.roomEditing
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUserDetails: () =>
      dispatch(actions.getUserDetails()),
    onUpdateUserDetails: (body) => {
      dispatch(actions.updateUserDetails(body))
    },
    onRoomEditing: () =>
      dispatch(actions.editRoomCreator()),
    onResetEditing: () =>
      dispatch(actions.resetEditing()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoomCreator));

