import React, { Component } from "react";

import cssClasses from "./ProfileActions.module.css";
import Button from "../../../../components/UI/Button/Button";
import RadioButton from "../../../../components/UI/RadioButton/RadioButton";
import EditButton from "../../../../components/UI/EditButton/EditButton";

class ProfileActions extends Component {

  state = {
    disabled: true
  }

  selectAvatarAppeanceInParent = (avatarAppearance) => {
    this.props.selected(avatarAppearance);
  }

  changeDisabledHandler = () => {
    this.setState({ disabled: !this.state.disabled });
  }

  render() {
    let style = null;

    if (this.props.style !== null) {
      style = this.props.style;
    }

    return (
      <div className={cssClasses.ProfileActions} style={style}>
        <div className={cssClasses.OtherActions}>
          <div style={{ width: "155px", display: "flex", flexDirection: "column" }}>
            <p
              style={{
                font: "normal normal bold 16px/18px Arial",
                height: "17px",
                width: "175px",
                margin: "0 0 11px 0 ",
                textAlign: "left",
              }}
            >
              Avatar Appearance*
            </p>
            <div style={{ width: "175px",height:"20px", display: "flex" }}>
              <RadioButton id="1" label="Female" value="Female" previousAvatarAppearance={this.props.previousAvatarAppearance} callback={this.selectAvatarAppeanceInParent} disabled={this.state.disabled} />
              <RadioButton id="2" label="Male" value="Male" previousAvatarAppearance={this.props.previousAvatarAppearance} callback={this.selectAvatarAppeanceInParent} disabled={this.state.disabled} />
            </div>
          </div>
          <EditButton clicked={this.changeDisabledHandler} />
        </div>
        <div style={{ width:"328px", display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
          <Button type="submit" form="register-form">{this.props.buttonName}</Button>
        </div>
      </div>
    );
  }
}

export default ProfileActions;
