import React, { Component } from "react";

import cssClasses from "./Auth.module.css";

class Auth extends Component {
  render() {
    return (
      <div className={cssClasses.Auth}>
        {this.props.children}
      </div>
    );
  }
}

export default Auth;
