import React, { Component } from "react";
import { connect } from "react-redux";

import Toolbar from "../../components/Navigation/NavigationItems/Toolbar/Toolbar";
import SaasTool from "../../components/SaasTool/SaasTool";

import authCssClasses from "../Auth/Auth.module.css";
import * as actions from "../../store/actions/index";

class MainApp extends Component {

  componentDidMount() {
    console.log("expirationTime on opening main app: " + localStorage.getItem("expirationTime"));
    console.log("currentTime on opening main app: " + (new Date().getTime() - new Date().getMilliseconds()));
    console.log("currentTime/1000 on opening main app: " + ((new Date().getTime() - new Date().getMilliseconds()) / 1000));

    if (!localStorage.getItem("expirationTime")) {
      this.props.history.push("./login");
      return;
    }

    var timeRemaining = localStorage.getItem("expirationTime") - ((new Date().getTime() - new Date().getMilliseconds()) / 1000);
    console.log("Time remaining: " + timeRemaining);

    if (timeRemaining <= 0) {
      this.props.onLogout();
    } else {
      console.log("this.props.loginSuccessfull: " + this.props.loginSuccessfull);
      this.props.onUserDetails();
      this.props.onBoothCreator();
    }

    setTimeout(() => {
      this.props.onLogout();
    }, timeRemaining * 1000);
  }

  render() {
    let toolbar = null;
    let saasTool = null;

    if (this.props.userDetailsFetched) {
      toolbar = <Toolbar company={this.props.userDetails.company} />;
      saasTool = <SaasTool name={this.props.userDetails.name} />
    }

    return (
      <div className={authCssClasses.Auth}>
        {toolbar}
        {saasTool}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.mainApp.userDetails,
    userDetailsFetched: state.mainApp.userDetailsFetched,
    loginSuccessfull: state.auth.loginSuccessfull
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUserDetails: () =>
      dispatch(actions.getUserDetails()),
    onLogout: () =>
      dispatch(actions.logout()),
    onBoothCreator: () =>
      dispatch(actions.boothCreator()),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
