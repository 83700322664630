import * as actionTypes from "./actionTypes";
import axios from "axios";

export const authLogin = () => {
    return {
        type: actionTypes.AUTH_LOGIN,
        error: false
    };
};

export const authLoginSuccess = (idToken, refreshToken, expirationTime) => {
    return {
        type: actionTypes.AUTH_LOGIN_SUCCESS,
        idToken: idToken,
        refreshToken: refreshToken,
        expirationTime: expirationTime,
        error: false
    };
};

export const authLoginFail = (message) => {
    return {
        type: actionTypes.AUTH_LOGIN_FAIL,
        error: true,
        message: message
    };
};

export const authLogout = (message) => {
    return {
        type: actionTypes.AUTH_LOGOUT,
        error: false,
        message: message
    }
}

export const checkAuthTimeout = (expirationTime) => {

    var timeRemaining = expirationTime - ((new Date().getTime() - new Date().getMilliseconds()) / 1000);

    return dispatch => {
        setTimeout(() => {
            dispatch(authLogout());
        }, timeRemaining * 1000);
    }
}

export const login = (email, password) => {

    return (dispatch) => {
        dispatch(authLogin());
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/login', {
            email: email,
            password: password
        })
            .then((res) => {
                localStorage.setItem("idToken", JSON.stringify(res.data.userDetails.idToken));
                localStorage.setItem("refreshToken", JSON.stringify(res.data.userDetails.refreshToken));
                localStorage.setItem("expirationTime", JSON.stringify(res.data.userDetails.expirationTime));
                dispatch(authLoginSuccess(res.data.userDetails.idToken, res.data.userDetails.refreshToken, localStorage.getItem("expirationTime")));
                // localStorage.setItem("expirationTime", ((new Date().getTime() - new Date().getMilliseconds()) / 1000) + 10);
                // dispatch(checkAuthTimeout(res.data.userDetails.expirationTime));
                // dispatch(checkAuthTimeout(((new Date().getTime() - new Date().getMilliseconds()) / 1000) + 10));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.general);
                    dispatch(authLoginFail(error.response.data.general));
                }
            })
    }
};

export const logout = () => {
    return (dispatch) => {
        localStorage.removeItem("idToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expirationTime");
        dispatch(authLogout("Logout Successful!"));
    }
};