import React from "react";

import cssClasses from "./ChangePasswordActions.module.css";
import Button from "../../../../components/UI/Button/Button";

const changePasswordActions = (props) => {
  return (
    <div className={cssClasses.ChangePasswordActions}>
      <Button type ="submit" form="change-password-form">Send</Button>
    </div>
  );
};

export default changePasswordActions;
