import * as actionTypes from "./actionTypes";
import axios from "axios";
import * as actions from "./index";

export const userScheduleMeeting = () => {
    return {
        type: actionTypes.SCHEDULE_MEETING,
        error: false
    };
};

export const userScheduleMeetingSuccess = (message) => {
    return {
        type: actionTypes.SCHEDULE_MEETING_SUCCESS,
        error: false,
        message: message
    };
};

export const userScheduleMeetingFail = (message) => {
    return {
        type: actionTypes.SCHEDULE_MEETING_FAIL,
        error: true,
        message: message
    };
};

export const userScheduleMeetingUpdateIndex = (index) => {
    return {
        type: actionTypes.SCHEDULE_MEETING_UPDATE_INDEX,
        newMeetingIndex: index
    };
};

export const scheduleMeeting = (body) => {
    return (dispatch) => {
        dispatch(userScheduleMeeting());
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/sendemail', {
            subject: body.subject,
            date: body.date,
            virtualRoom: body.virtualRoom,
            hostAccessCode: body.hostAccessCode,
            visitorAccessCode: body.visitorAccessCode,
            participants: body.participants
        })
            .then(() => {
                dispatch(userScheduleMeetingSuccess("Meeting scheduled."));

                let meetingBody = {
                    meetings: body.meetingsArray
                }

                dispatch(actions.updateUserDetails(meetingBody));
            })
            .catch((error) => {
                if (error.response) {
                    // console.log("ERR: " + error.response.data.message);
                    dispatch(userScheduleMeetingFail(error.response.data.message));
                }
            })
    }
}