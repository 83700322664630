import * as actionTypes from "./actionTypes";

export const authErrorResetSuccess = () => {
    return {
        type: actionTypes.AUTH_ERROR_RESET,
        error: false,
        message: null
    }
}

export const authErrorReset = () => {
    return (dispatch) => {
        dispatch(authErrorResetSuccess());
    }
}