import React, { Component } from "react";
import { connect } from "react-redux";
import AuthError from "../../../containers/Auth/AuthError/AuthError";
import AuthSuccess from "../../../containers/Auth/AuthSuccess/AuthSuccess";

// import LoadingCube from "../../../components/UI/LoadingCube/LoadingCube";
import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import cssClasses from "./ScheduleMeeting.module.css";

import * as actions from "../../../store/actions/index";

class ScheduleMeeting extends Component {

    state = {
        meetingForm: {
            subject: {
                valid: true,
                value: "",
                validation: {
                    required: true,
                    minLength: 3,
                    maxLength: 20,
                }
            },
            date: {
                startTime: "",
                startDate: "",
                endTime: "",
                endDate: "",
                timezone: "",
            },
            virtualRoom: "",
            hostMeetingURL: "",
            visitorMeetingURL: "",
            hostAccessCode: "",
            visitorAccessCode: "",
            participants: {
                valid: true,
                value: "",
                validation: {
                    required: true,
                    minLength: 1,
                    maxLength: 100,
                }
            }
        }
    }

    componentDidMount() {
        let tempAccessCodeHost = this.generateAccessCode();
        let tempAccessCodeVisitor = this.generateAccessCode();

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        today = yyyy + '-' + mm + '-' + dd;

        var todayDate = new Date();
        var hh = todayDate.getHours();
        var mm = todayDate.getMinutes();

        if (mm > 0) {
            hh = hh + 1;
        }

        var startTime = hh + ":00";
        var endTime = hh + 1 + ":00";

        let startTimeString = startTime.split(":");
        if (startTimeString[0] < 10) {
            startTime = "0" + startTime;
        }

        let endTimeString = endTime.split(":");
        if (endTimeString[0] < 10) {
            endTime = "0" + endTime;
        }

        let updatedMeetingForm = {
            ...this.state.meetingForm,
            date: {
                ...this.state.meetingForm.date,
                startDate: today,
                startTime: startTime,
                endDate: today,
                endTime: endTime,
                timezone: "+05:50",
            },
            virtualRoom: this.props.rooms[0].name,
            hostMeetingURL: "https://staging.app.vooth.co/meeting?accessCode=" + tempAccessCodeHost,
            visitorMeetingURL: "https://staging.app.vooth.co/meeting?accessCode=" + tempAccessCodeVisitor,
            hostAccessCode: tempAccessCodeHost,
            visitorAccessCode: tempAccessCodeVisitor
        }
        this.setState({ meetingForm: updatedMeetingForm });
    }

    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== "" && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        return isValid;
    }

    isEmail = (value) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = re.test(String(value).toLowerCase());

        return isValid;
    }

    subjectChangeHandler = (event) => {
        let updatedMeetingForm = {
            ...this.state.meetingForm,
            subject: {
                ...this.state.meetingForm.subject,
                value: event.target.value
            }
        }

        this.setState({ meetingForm: updatedMeetingForm });
    }

    subjectChangeValidityHandler = () => {
        let updatedMeetingForm = {
            ...this.state.meetingForm,
            subject: {
                ...this.state.meetingForm.subject,
                valid: this.checkValidity(
                    this.state.meetingForm.subject.value,
                    this.state.meetingForm.subject.validation
                )
            }
        }

        this.setState({ meetingForm: updatedMeetingForm });
    }



    startDateChangeHandler = (event) => {
        let updatedMeetingForm = {
            ...this.state.meetingForm,
            date: {
                ...this.state.meetingForm.date,
                startDate: event.target.value
            }
        }

        this.setState({ meetingForm: updatedMeetingForm });
    }

    startTimeChangeHandler = (event) => {
        let updatedMeetingForm = {
            ...this.state.meetingForm,
            date: {
                ...this.state.meetingForm.date,
                startTime: event.target.value
            }
        }

        this.setState({ meetingForm: updatedMeetingForm });
    }

    endDateChangeHandler = (event) => {
        let updatedMeetingForm = {
            ...this.state.meetingForm,
            date: {
                ...this.state.meetingForm.date,
                endDate: event.target.value
            }
        }

        this.setState({ meetingForm: updatedMeetingForm });
    }

    endTimeChangeHandler = (event) => {
        let updatedMeetingForm = {
            ...this.state.meetingForm,
            date: {
                ...this.state.meetingForm.date,
                endTime: event.target.value
            }
        }

        this.setState({ meetingForm: updatedMeetingForm });
    }

    timezoneChangeHandler = (event) => {
        let updatedMeetingForm = {
            ...this.state.meetingForm,
            date: {
                ...this.state.meetingForm.date,
                timezone: event.target.value
            }
        }

        this.setState({ meetingForm: updatedMeetingForm });
    }

    virtualRoomChangeHandler = (event) => {
        let updatedMeetingForm = {
            ...this.state.meetingForm,
            virtualRoom: event.target.value
        }

        this.setState({ meetingForm: updatedMeetingForm });
    }

    meetingURLChangeHandler = (event) => {
        let updatedMeetingForm = {
            ...this.state.meetingForm,
            meetingURL: event.target.value
        }

        this.setState({ meetingForm: updatedMeetingForm });
    }

    accessCodeChangeHandler = (event) => {
        let updatedMeetingForm = {
            ...this.state.meetingForm,
            accessCode: {
                ...this.state.meetingForm.accessCode,
                value: event.target.value,
                valid: this.checkValidity(
                    event.target.value,
                    this.state.meetingForm.accessCode.validation
                )
            }
        }
        this.setState({ meetingForm: updatedMeetingForm });
    }

    participantsCheckValidity = () => {
        var array = this.state.meetingForm.participants.value.split(',');

        let updatedMeetingForm = {
            ...this.state.meetingForm,
            participants: {
                ...this.state.meetingForm.participants,
                valid: array.every(this.isEmail)
            }
        }

        this.setState({ meetingForm: updatedMeetingForm });
    }

    participantsChangeHandler = (event) => {
        event.preventDefault();
        let updatedMeetingForm = {
            ...this.state.meetingForm,
            participants: {
                ...this.state.meetingForm.participants,
                value: event.target.value
            }
        }

        this.setState({ meetingForm: updatedMeetingForm });
    }

    generateAccessCode = () => {
        // Declare a string variable 
        // which stores all string
        var string = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let OTP = "";

        // Find the length of string
        var len = string.length;
        for (let i = 0; i < 6; i++) {
            OTP += string[Math.floor(Math.random() * len)];
        }
        return OTP;
    }

    submitHandler = () => {
        if (this.state.meetingForm.subject.valid && this.state.meetingForm.participants.valid) {
            console.log("All are valid here");

            let newMeetingsArray = [];
            if (this.props.meetings != null) {
                newMeetingsArray = this.props.meetings;
            }

            newMeetingsArray.push({
                subject: this.state.meetingForm.subject.value,
                date: this.state.meetingForm.date,
                virtualRoom: this.state.meetingForm.virtualRoom,
                hostMeetingURL: this.state.meetingForm.hostMeetingURL,
                visitorMeetingURL: this.state.meetingForm.visitorMeetingURL,
                hostAccessCode: this.state.meetingForm.hostAccessCode,
                visitorAccessCode: this.state.meetingForm.visitorAccessCode,
                participants: this.state.meetingForm.participants.value
            })

            let body = {
                subject: this.state.meetingForm.subject.value,
                date: this.state.meetingForm.date,
                virtualRoom: this.state.meetingForm.virtualRoom,
                hostMeetingURL: this.state.meetingForm.hostMeetingURL,
                visitorMeetingURL: this.state.meetingForm.visitorMeetingURL,
                hostAccessCode: this.state.meetingForm.hostAccessCode,
                visitorAccessCode: this.state.meetingForm.visitorAccessCode,
                participants: this.state.meetingForm.participants.value,
                meetingsArray: newMeetingsArray
            }

            this.props.onScheduleMeeting(body);
        } else if (!this.state.meetingForm.subject.valid) {
            this.props.onAuthError("Subject is invalid");
        } else if (!this.state.meetingForm.participants.valid) {
            this.props.onAuthError("Participants' emails are invalid");
        }
    }

    render() {

        let rooms = null;
        const roomsArray = [];

        for (let key in this.props.rooms) {
            roomsArray.push({
                id: key,
                data: this.props.rooms[key],
            });
        }

        rooms = roomsArray.map((room, index) => (
            <option
                key={room.data.id}
                value={room.data.name}
                defaultValue={room.data.name}
            >{room.data.name}</option>
        ));

        let scheduleMeeting = <div className={cssClasses.ScheduleMeeting}>
            <div className={cssClasses.ScheduleMeetingHeadingText}>
                Schedule Meeting
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Subject</p>
                <input className={this.state.meetingForm.subject.valid ? cssClasses.InputStyle : cssClasses.Invalid} onChange={(event) => this.subjectChangeHandler(event)} onBlur={this.subjectChangeValidityHandler}></input>
            </div>
            <div className={cssClasses.DateParentInput}>
                <p className={cssClasses.TextStyle}>Date</p>
                <div className={cssClasses.DateTimeParent}>
                    <input className={cssClasses.DateInput} value={this.state.meetingForm.date.startDate} type="date" id="startDate" onChange={(event) => this.startDateChangeHandler(event)} />
                    <input className={cssClasses.TimeInput} value={this.state.meetingForm.date.startTime} type="time" id="startTime" onChange={(event) => this.startTimeChangeHandler(event)} />
                    <p className={cssClasses.ToText}>to</p>
                    <input className={cssClasses.DateInput} value={this.state.meetingForm.date.endDate} type="date" id="endDate" onChange={(event) => this.endDateChangeHandler(event)} />
                    <input className={cssClasses.TimeInput} value={this.state.meetingForm.date.endTime} type="time" id="endTime" onChange={(event) => this.endTimeChangeHandler(event)} />
                </div>
                <div className={cssClasses.TimeZoneInputParent}>
                    <p style={{ font: "normal normal 13px/15px Arial", margin: "0 0 0 0", display: "flex", justifyContent: "center", textAlign: "center" }}>Timezone: </p>
                    <select value={this.state.meetingForm.date.timezone} style={{border:"0"}} name="timezone_offset" id="timezone-offset" onChange={(event) => this.timezoneChangeHandler(event)}>
                        {/* <select style={{ height: "100%", width: "100%", border: "0" }} value={this.state.meetingForm.date.timezone} className={cssClasses.TimeZoneInput} name="timezone_offset" id="timezone-offset" onChange={(event) => this.timezoneChangeHandler(event)}> */}
                        <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                        <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                        <option value="-10:00">(GMT -10:00) Hawaii</option>
                        <option value="-09:50">(GMT -9:30) Taiohae</option>
                        <option value="-09:00">(GMT -9:00) Alaska</option>
                        <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                        <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                        <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                        <option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                        <option value="-04:50">(GMT -4:30) Caracas</option>
                        <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                        <option value="-03:50">(GMT -3:30) Newfoundland</option>
                        <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                        <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                        <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                        <option value="+00:00" selected="selected">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                        <option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                        <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                        <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                        <option value="+03:50">(GMT +3:30) Tehran</option>
                        <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                        <option value="+04:50">(GMT +4:30) Kabul</option>
                        <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                        <option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                        <option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</option>
                        <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                        <option value="+06:50">(GMT +6:30) Yangon, Mandalay</option>
                        <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                        <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                        <option value="+08:75">(GMT +8:45) Eucla</option>
                        <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                        <option value="+09:50">(GMT +9:30) Adelaide, Darwin</option>
                        <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                        <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
                        <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                        <option value="+11:50">(GMT +11:30) Norfolk Island</option>
                        <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                        <option value="+12:75">(GMT +12:45) Chatham Islands</option>
                        <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                        <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                    </select>
                </div>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Virtual Room</p>
                <select className={cssClasses.InputStyle} value={this.state.meetingForm.virtualRoom} onChange={(event) => this.virtualRoomChangeHandler(event)}>
                    {rooms}
                </select>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Meeting URL Host (PC)</p>
                <p className={cssClasses.InputStyleDisabled}>{this.state.meetingForm.hostMeetingURL}</p>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Meeting URL Visitor (PC)</p>
                <p className={cssClasses.InputStyleDisabled}>{this.state.meetingForm.visitorMeetingURL}</p>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Access Code Host</p>
                <p className={cssClasses.InputStyleDisabled}>{this.state.meetingForm.hostAccessCode}</p>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Access Code Visitor</p>
                <p className={cssClasses.InputStyleDisabled}>{this.state.meetingForm.visitorAccessCode}</p>
            </div>
            <div className={cssClasses.InputElement}>
                <p className={cssClasses.TextStyle}>Participants</p>
                <input className={this.state.meetingForm.participants.valid ? cssClasses.InputStyle : cssClasses.Invalid} onChange={(event) => this.participantsChangeHandler(event)} onBlur={this.participantsCheckValidity}></input>
            </div>
            <button
                className={cssClasses.Button}
                onClick={this.submitHandler}
            >
                <p style={{ height: "57.14%", width: "86.84%", font: "normal normal bold 14px/16px Arial", margin: "3.95% 6.56% 3.95% 6.56%", color: "#FFFFFF", display: "flex", justifyContent: "center", alignItems: "center" }}>Save</p>
            </button>
        </div>;

        if (this.props.loading) {
            // scheduleMeeting = <div className={cssClasses.ScheduleMeeting}><LoadingSpinner /></div>
            scheduleMeeting = <LoadingSpinner />
        }

        if (this.props.error) {
            scheduleMeeting = <AuthError authError={this.props.message} />
        }

        if (!this.props.error && this.props.scheduleMeeting) {
            scheduleMeeting = <AuthSuccess authSuccess={this.props.message} />
        }

        let errorComponent = null;

        if (this.props.errorValidation) {
            errorComponent = <AuthError authError={this.props.messageValidation} />;
        }

        if (this.state.meetingForm.subject.valid && this.state.meetingForm.participants.valid) {
            errorComponent = null;
        }

        return (
            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                {scheduleMeeting}
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {errorComponent}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.mainApp.loading,
        scheduleMeeting: state.mainApp.scheduleMeeting,
        error: state.mainApp.error,
        message: state.mainApp.message,
        errorValidation: state.auth.error,
        messageValidation: state.auth.message,
        meetings: state.mainApp.userDetails.meetings,
        rooms: state.mainApp.userDetails.rooms
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onScheduleMeeting: (body) =>
            dispatch(actions.scheduleMeeting(body)),
        onAuthError: (error) =>
            dispatch(actions.authError(error))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleMeeting);