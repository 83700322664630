import React, { Component } from "react";
import { connect } from "react-redux";

import cssClasses from "./ChangePassword.module.css";
import Input from "../../../components/UI/Input/Input";
import HeaderLogo from "../../../components/HeaderLogo/HeaderLogo";
// import LoadingCube from "../../../components/UI/LoadingCube/LoadingCube";
import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import AuthError from "../AuthError/AuthError";

import ChangePasswordActions from "./ChangePasswordActions/ChangePasswordActions";
import * as actions from "../../../store/actions/index";

class ChangePassword extends Component {
  state = {
    controls: {
      newpassword: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "",
          label: "New Password*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 6,
          maxLength: 10,
        },
        valid: false,
        touched: false,
      },
      confirmpassword: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "",
          label: "Confirm Password*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 6,
          maxLength: 10,
          isConfirmPassword: true
        },
        valid: false,
        touched: false,
      },
    },
    loading: false,
    authError: {
      value: false,
      errorMessage: ""
    },
    oobCode: "",
    error: false,
    message: ""
  };

  componentDidMount() {
    this.saveOobCode(this.props.location.search);
    this.props.onResetError();

    if (this.props.error) {
      this.setState({ controls: { ...this.state.controls }, error: true, message: this.props.message })
    }
  }

  saveOobCode = (params) => {
    var searchParams = new URLSearchParams(params);
    if (searchParams.get("oobCode")) {
      var updatedOobCode = {
        ...this.state.oobCode
      };

      updatedOobCode = searchParams.get("oobCode").toString();

      this.props.onVerifyPasswordReset(updatedOobCode);
    }
  }

  checkValidity(value, rules) {
    let isValid = true;

    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase());
    }

    if (rules.isConfirmPassword) {
      isValid = value === this.state.controls.newpassword.value;
    }

    return isValid;
  }

  inputChangeHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true
      }
    }
    this.setState({ controls: updatedControls });
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (this.state.controls.newpassword.valid && this.state.controls.confirmpassword.valid) {

      this.props.onChangePassword(
        this.props.oobCode,
        this.state.controls.newpassword.value
      )

    } else if (!this.state.controls.newpassword.valid) {
      this.props.onAuthError("New password is invalid");
    } else if (!this.state.controls.confirmpassword.valid) {
      this.props.onAuthError("Confirm password is invalid");
    }
  }

  render() {

    if (localStorage.getItem("idToken") !== null) {
      this.props.history.push("./mainapp");
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    let form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangeHandler(event, formElement.id)}
        label={formElement.config.elementConfig.label}
      ></Input>
    ));

    let changePasswordForm = <form id="change-password-form" className={cssClasses.ChangePassword} onSubmit={this.submitHandler}>
      <h1
        style={{
          color: "#000000",
          margin: "0 0 1.98% 0",
          font: "normal normal bold 24px/28px Arial",
          alignSelf: "center",
        }}
      >
        Change Password
      </h1>
      {form}
      <ChangePasswordActions />
    </form>;

    if (this.props.loading) {
      // changePasswordForm = <div className={cssClasses.ChangePassword}><LoadingSpinner /></div>;
      changePasswordForm = <LoadingSpinner />
    }

    let authErrorComponent = null;

    if (this.props.error) {
      authErrorComponent = <AuthError authError={this.state.message} />;
    }

    if (this.props.changePasswordSuccessfull) {
      this.props.history.push({
        pathname: "/login",
        search: "?message=Your password has been updated."
      })
    }

    return (
      <div className={cssClasses.ChangePasswordParent}>
        <HeaderLogo />
        {changePasswordForm}
        {authErrorComponent}
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    changePasswordSuccessfull: state.auth.changePasswordSuccessfull,
    error: state.auth.error,
    message: state.auth.message,
    oobCode: state.auth.oobCode
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onVerifyPasswordReset: (oobCode) =>
      dispatch(actions.verifyPasswordReset(oobCode)),
    onChangePassword: (oobCode, updatedPassword) =>
      dispatch(actions.changePassword(oobCode, updatedPassword)),
    onAuthError: (error) =>
      dispatch(actions.authError(error)),
    onResetError: () =>
      dispatch(actions.authErrorReset())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
