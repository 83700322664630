import React, { Component } from "react";

import cssClasses from "./RegisterActions.module.css";
import Button from "../../../../components/UI/Button/Button";
import RadioButton from "../../../../components/UI/RadioButton/RadioButton";

class RegisterActions extends Component {

  selectAvatarAppeanceInParent = (avatarAppearance) => {
    this.props.selected(avatarAppearance);
  }

  render() {
    let style = null;

    if (this.props.style !== null) {
      style = this.props.style;
    }

    return (
      <div className={cssClasses.RegisterActions} style={style}>
        <div className={cssClasses.OtherActions}>
          <p
            style={{
              font: "normal normal bold 16px/18px Arial",
              height: "17px",
              width: "175px",
              margin: "0 0 11px 0 ",
              textAlign: "left",
            }}
          >
            Avatar Appearance*
          </p>
          <div
            style={{ width: "175px", display: "flex", flexDirection: "coloumn" }}
          >
            <RadioButton id="1" label="Female" value="Female" previousAvatarAppearance={this.props.previousAvatarAppearance} callback={this.selectAvatarAppeanceInParent} />
            <RadioButton id="2" label="Male" value="Male" previousAvatarAppearance={this.props.previousAvatarAppearance} callback={this.selectAvatarAppeanceInParent} />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
          <Button type="submit" form="register-form">{this.props.buttonName}</Button>
        </div>
      </div>
    );
  }
}

export default RegisterActions;
