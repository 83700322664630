export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";

export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_FAIL = "AUTH_REGISTER_FAIL";

export const AUTH_FORGOT_PASSWORD = "AUTH_FORGOT_PASSWORD";
export const AUTH_FORGOT_PASSWORD_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS";
export const AUTH_FORGOT_PASSWORD_FAIL = "AUTH_FORGOT_PASSWORD_FAIL";

export const AUTH_VERIFY_PASSWORD_RESET = "AUTH_VERIFY_PASSWORD_RESET";
export const AUTH_VERIFY_PASSWORD_RESET_SUCCESS = "AUTH_VERIFY_PASSWORD_RESET_SUCCESS";
export const AUTH_VERIFY_PASSWORD_RESET_FAIL = "AUTH_VERIFY_PASSWORD_RESET_FAIL";

export const AUTH_CHANGE_PASSWORD = "AUTH_CHANGE_PASSWORD";
export const AUTH_CHANGE_PASSWORD_SUCCESS = "AUTH_CHANGE_PASSWORD_SUCCESS";
export const AUTH_CHANGE_PASSWORD_FAIL = "AUTH_CHANGE_PASSWORD_FAIL";

export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const AUTH_SAVE_OOBCODE = "AUTH_SAVE_OOBCODE";

export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_ERROR_RESET = "AUTH_ERROR_RESET";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

export const PROFILE_CHANGE_EMAIL = "PROFILE_CHANGE_EMAIL";
export const PROFILE_CHANGE_EMAIL_SUCCESS = "PROFILE_CHANGE_EMAIL_SUCCESS";
export const PROFILE_CHANGE_EMAIL_FAIL = "PROFILE_CHANGE_EMAIL_FAIL";

export const PROFILE_CHANGE_PASSWORD = "PROFILE_CHANGE_PASSWORD";
export const PROFILE_CHANGE_PASSWORD_UPDATE_REQUEST = "PROFILE_CHANGE_PASSWORD_UPDATE_REQUEST";
export const PROFILE_CHANGE_PASSWORD_SUCCESS = "PROFILE_CHANGE_PASSWORD_SUCCESS";
export const PROFILE_CHANGE_PASSWORD_FAIL = "PROFILE_CHANGE_PASSWORD_FAIL";

export const PROFILE_UPDATE_USER_DETAILS = "PROFILE_UPDATE_USER_DETAILS";
export const PROFILE_UPDATE_USER_DETAILS_SUCCESS = "PROFILE_UPDATE_USER_DETAILS_SUCCESS";
export const PROFILE_UPDATE_USER_DETAILS_FAIL = "PROFILE_UPDATE_USER_DETAILS_FAIL";

export const BOOTH_CREATOR = "BOOTH_CREATOR";
export const ROOM_CREATOR = "ROOM_CREATOR";
export const MEETINGS = "MEETINGS";
export const ANALYTICS = "ANALYTICS";

export const ENABLE_SCHEDULE_MEETING_BOX = "ENABLE_SCHEDULE_MEETING_BOX";
export const DISABLE_SCHEDULE_MEETING_BOX = "DISABLE_SCHEDULE_MEETING_BOX";

export const SCHEDULE_MEETING = "SCHEDULE_MEETING";
export const SCHEDULE_MEETING_SUCCESS = "SCHEDULE_MEETING_SUCCESS";
export const SCHEDULE_MEETING_FAIL = "SCHEDULE_MEETING_FAIL";
export const SCHEDULE_MEETING_STATE_RESET = "SCHEDULE_MEETING_STATE_RESET";
export const SCHEDULE_MEETING_UPDATE_INDEX = "SCHEDULE_MEETING_UPDATE_INDEX";

export const ENABLE_UPDATE_MEETING_BOX = "ENABLE_UPDATE_MEETING_BOX";
export const DISABLE_UPDATE_MEETING_BOX = "DISABLE_UPDATE_MEETING_BOX";

export const UPDATE_MEETING = "UPDATE_MEETING";
export const UPDATE_MEETING_SUCCESS = "UPDATE_MEETING_SUCCESS";
export const UPDATE_MEETING_FAIL = "UPDATE_MEETING_FAIL";
export const UPDATE_MEETING_STATE_RESET = "UPDATE_MEETING_STATE_RESET";

export const EDIT_BOOTH = "EDIT_BOOTH";
export const EDIT_ROOM = "EDIT_ROOM";
export const RESET_EDITING = "RESET_EDITING";