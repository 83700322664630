import * as actionTypes from "./actionTypes";

export const enableRoomCreator = () => {
    return {
        type: actionTypes.ROOM_CREATOR
    };
};

export const editRoom = () => {
    return {
        type: actionTypes.EDIT_ROOM
    };
};
export const editRoomCreator = () => {
    return (dispatch) => {
        dispatch(editRoom());
    }
};

export const roomCreator = () => {
    return (dispatch) => {
        dispatch(enableRoomCreator());
    }
};