import React, { Component } from "react";
import { connect } from "react-redux";

import cssClasses from "./Register.module.css";
import Input from "../../../components/UI/Input/Input";
import HeaderLogo from "../../../components/HeaderLogo/HeaderLogo";
// import LoadingCube from "../../../components/UI/LoadingCube/LoadingCube";
import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import AuthError from "../AuthError/AuthError";
import AuthSuccess from "../AuthSuccess/AuthSuccess";
import Button from "../../../components/UI/Button/Button";

import RegisterActions from "./RegisterActions/RegisterActions";
import * as actions from "../../../store/actions/index";

class Register extends Component {
  state = {
    controls: {
      name: {
        elementType: "input",
        elementConfig: {
          type: "name",
          placeholder: "",
          label: "Your Name*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 3,
          maxLength: 10,
        },
        valid: false,
        touched: false,
      },
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "",
          label: "Email*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: true,
        },
        valid: false,
        touched: false,
      },
      company: {
        elementType: "input",
        elementConfig: {
          type: "company",
          placeholder: "",
          label: "Company*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 3,
          maxLength: 20,
        },
        valid: false,
        touched: false,
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "",
          label: "Password*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 6,
          maxLength: 10,
        },
        valid: false,
        touched: false,
      },
      confirmPassword: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "",
          label: "Confirm Password*",
        },
        value: "",
        validation: {
          required: true,
          isEmail: false,
          minLength: 6,
          maxLength: 10,
          isConfirmPassword: true
        },
        valid: false,
        touched: false,
      },
    },
    avatarAppearance: "",
  };

  componentDidMount() {
    this.props.onResetError();
    this.resetRegisterFormValuesHandler();
  }

  checkValidity(value, rules) {
    let isValid = true;

    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase());
    }

    if (rules.isConfirmPassword) {
      isValid = value === this.state.controls.password.value;
    }

    return isValid;
  }

  inputChangeHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true
      }
    }
    this.setState({ controls: updatedControls });
  }

  avatarAppearanceSelectionHandler = (avatarAppearance) => {

    let oldAvatarAppearance = {
      ...this.state.avatarAppearance
    }

    oldAvatarAppearance = avatarAppearance;

    this.setState({ avatarAppearance: oldAvatarAppearance });
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (this.state.controls.name.valid && this.state.controls.email.valid && this.state.controls.company.valid && this.state.controls.password.valid && this.state.controls.confirmPassword.valid && this.state.avatarAppearance.length !== 0) {

      let body = {
        username: this.state.controls.name.value + "_" + ((new Date().getTime() - new Date().getMilliseconds()) / 1000),
        name: this.state.controls.name.value,
        email: this.state.controls.email.value,
        company: this.state.controls.company.value,
        password: this.state.controls.password.value,
        avatarappearance: this.state.avatarAppearance
      }

      this.props.onRegister(body);

    } else if (!this.state.controls.name.valid) {
      this.props.onAuthError("Name is invalid");
    } else if (!this.state.controls.email.valid) {
      this.props.onAuthError("Email is invalid");
    } else if (!this.state.controls.company.valid) {
      this.props.onAuthError("Company is invalid");
    } else if (!this.state.controls.password.valid) {
      this.props.onAuthError("Password is invalid");
    } else if (!this.state.controls.confirmPassword.valid) {
      this.props.onAuthError("Confirm Password is invalid");
    } else if (this.state.avatarAppearance.length === 0) {
      this.props.onAuthError("Avatar appearance is invalid");
    }
  }

  //Reset the register form input value 
  resetRegisterFormValuesHandler = () => {
    const updatedControls = {
      ...this.state.controls,
      name: {
        ...this.state.controls.name,
        value: ""
      },
      email: {
        ...this.state.controls.email,
        value: ""
      },
      company: {
        ...this.state.controls.company,
        value: ""
      },
      password: {
        ...this.state.controls.password,
        value: ""
      },
      confirmPassword: {
        ...this.state.controls.confirmPassword,
        value: ""
      }
    }
    this.setState({ controls: updatedControls, avatarAppearance: "" });
  }

  render() {

    if (localStorage.getItem("idToken") !== null) {
      this.props.history.push("./mainapp");
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    let form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangeHandler(event, formElement.id)}
        label={formElement.config.elementConfig.label}
      ></Input>
    ));

    let registerForm = <form id="register-form" className={cssClasses.Register} onSubmit={this.submitHandler}>
      <h1
        style={{
          color: "#000000",
          margin: "0 0 1.98% 0",
          font: "normal normal bold 24px/28px Arial",
        }}
      >
        Create Account
      </h1>
      {form}
      <RegisterActions selected={this.avatarAppearanceSelectionHandler} previousAvatarAppearance={this.state.avatarAppearance} buttonName="Send" />
    </form>;

    if (this.props.loading) {
      // registerForm = <div className={cssClasses.Register}><LoadingSpinner /></div>;
      registerForm = <LoadingSpinner />
    }

    let authErrorComponent = null;
    let authSuccessComponent = null;
    let goToGmailComponent = null;

    if (this.props.error) {
      authErrorComponent = <AuthError authError={this.props.message} />;
    }

    if (this.props.message && !this.props.error) {
      authSuccessComponent = <AuthSuccess authSuccess={this.props.message} />;
      goToGmailComponent = <div style={{ margin: "0 0 1.74% 0" }}>
        <Button clicked={() => window.open("https://www.gmail.com", "_blank")}>Go to gmail</Button>
      </div>
    }

    return (
      <div className={cssClasses.RegisterParent}>
        <HeaderLogo />
        {registerForm}
        {goToGmailComponent}
        {authErrorComponent}
        {authSuccessComponent}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    message: state.auth.message
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRegister: (body) =>
      dispatch(actions.register(body)),
    onAuthError: (error) =>
      dispatch(actions.authError(error)),
    onResetError: () =>
      dispatch(actions.authErrorReset())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
