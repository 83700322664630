import React from "react";
import cssClasses from "./UserCompanyName.module.css";

const userCompanyName = (props) => {
    return (
        <div className={cssClasses.UserCompanyName}>
            <p>{props.companyName}</p>
        </div>
    )
}

export default userCompanyName;