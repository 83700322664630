import * as actionTypes from "./actionTypes";

export const enableBoothCreator = () => {
    return {
        type: actionTypes.BOOTH_CREATOR
    };
};

export const editBooth = () => {
    return {
        type: actionTypes.EDIT_BOOTH
    };
};

export const ResetEditing = () => {
    return {
        type: actionTypes.RESET_EDITING
    };
};

export const boothCreator = () => {
    return (dispatch) => {
        dispatch(enableBoothCreator());
    }
};

export const editBoothCreator = () => {
    return (dispatch) => {
        dispatch(editBooth());
    }
};

export const resetEditing = () => {
    return (dispatch) => {
        dispatch(ResetEditing());
    }
};