import React from "react";

import cssClasses from "./LoginActions.module.css";
import Button from "../../../../components/UI/Button/Button";
import { Link } from "react-router-dom";

const loginActions = (props) => {
  return (
    <div className={cssClasses.LoginActions}>
      <div style={{ height: "70px", width: "175px" }}>
        <Link className={cssClasses.OtherActions} to="/register" cl>Register</Link>
        <Link className={cssClasses.OtherActions} to="/forgotpassword">Forgot Password?</Link>
      </div>
      <div>
        <Button type="submit" form="login-form">Send</Button>
      </div>
    </div>
  );
};

export default loginActions;
