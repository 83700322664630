import React, { Component } from "react";
import cssClasses from "./RadioButton.module.css";

class RadioButton extends Component {

  selectAvatarAppearanceInParent1 = (avatarAppearance) => {
    this.props.callback(avatarAppearance);
  }
  render() {
    return (
      <div className={cssClasses.RadioButton}>
        <input
          style={{ height: "20px", width: "20px", margin: " 0 3px 0 0"}}
          id={this.props.id}
          value={this.props.value}
          type="radio"
          checked={this.props.value === this.props.previousAvatarAppearance}
          onChange={() => this.selectAvatarAppearanceInParent1(this.props.value)}
          disabled={this.props.disabled}
        />
        <label htmlFor={this.props.id}>{this.props.label}</label>
      </div>
    );
  }
}

export default RadioButton;
