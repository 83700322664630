import React, { Component } from "react";
import { connect } from "react-redux";

import cssClasses from "./InputEditable.module.css";
import EditButton from "../EditButton/EditButton";
import closeImage from "../../../assets/images/close.png";
import LoadingBars from "../../../components/UI/LoadingBars/LoadingBars";

import * as actions from "../../../store/actions/index";

class InputEditable extends Component {
  state = {
    disabled: true
  }

  componentDidUpdate = () => {
    if (this.props.elementConfig.type === "password") {
      if (!this.state.disabled) {
        this.props.onStartPasswordUpdating();
      }
    }
  }

  componentDidMount = () => {
    if (this.props.elementConfig.type === "newpassword" || this.props.elementConfig.type === "confirmpassword") {
      console.log("1");
      this.setState({ disabled: false });
    }
  }

  render() {

    let inputElement = null;
    const inputClasses = [cssClasses.InputElement];

    if (this.props.invalid && this.props.shouldValidate && this.props.touched) {
      inputClasses.push(cssClasses.Invalid);
    }

    let editButton = null;

    if (this.props.editable) {
      editButton = <EditButton clicked={() => {
        if (!this.state.disabled) {
          this.setState({ disabled: true });
        }
        else {
          this.setState({ disabled: false });
        }
      }} />;
    }

    switch (this.props.elementType) {
      case "input": {
        inputElement = (
          <input
            className={inputClasses.join(" ")}
            {...this.props.elementConfig}
            value={this.props.value}
            onChange={this.props.changed}
            autoComplete="on"
            disabled={(this.state.disabled) ? "disabled" : ""}
            onBlur={this.props.blur}
          />
        );
        break;
      }
      case "textarea": {
        inputElement = (
          <textarea
            className={inputClasses.join(" ")}
            {...this.props.elementConfig}
            value={this.props.value}
            onChange={this.props.changed}
            disabled={(this.state.disabled) ? "disabled" : ""}
          />
        );
        break;
      }
      case "select": {
        inputElement = (
          <select
            className={inputClasses.join(" ")}
            {...this.props.elementConfig}
            value={this.props.value}
            onChange={this.props.changed}
          >
            {this.props.elementConfig.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.displayValue}
              </option>
            ))}
          </select>
        );
        break;
      }
      case "radio": {
        inputElement = (
          <textarea
            className={inputClasses.join(" ")}
            {...this.props.elementConfig}
            value={this.props.value}
            onChange={this.props.changed}
          />
        );
        break;
      }
      default: {
        inputElement = (
          <input
            className={inputClasses.join(" ")}
            {...this.props.elementConfig}
            value={this.props.value}
          />
        );
        break;
      }
    }

    let action = null;

    if (this.props.elementConfig.type === "email") {
      if (this.props.emailUpdating) {
        action = <LoadingBars />
      }
      else {
        action = editButton
      }
    } else if (this.props.elementConfig.type === "newpassword") {
      if (this.props.passwordUpdating) {
        action = <img src={closeImage} onClick={this.props.onFinishPasswordUpdating} style={{ height: "30", width: "30px" }}></img>
      }
      else {
        action = editButton
      }
    } else if (this.props.elementConfig.type === "confirmpassword") {
      if (this.props.passwordUpdateRequest) {
        action = <LoadingBars />
      }
      else {
        action = editButton
      }
    }
    else {
      action = editButton
    }

    return (
      <div className={cssClasses.InputEditableParent}>
        <div className={cssClasses.InputEditable}>
          <label className={cssClasses.Label}>{this.props.label}</label>
          {inputElement}
        </div>
        {action}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emailUpdating: state.auth.emailUpdating,
    passwordUpdating: state.auth.passwordUpdating,
    passwordUpdateRequest: state.auth.passwordUpdateRequest
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onStartPasswordUpdating: () =>
      dispatch(actions.profileChangePassword()),
    onFinishPasswordUpdating: () =>
      dispatch(actions.profileChangePasswordFail())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputEditable);