import * as actionTypes from "./actionTypes";
import axios from "axios";
import * as actions from "./index";

export const profileUpdateUserDetails = () => {
    return {
        type: actionTypes.PROFILE_UPDATE_USER_DETAILS,
        error: false
    };
};

export const profileUpdateUserDetailsSuccess = (message) => {
    return {
        type: actionTypes.PROFILE_UPDATE_USER_DETAILS_SUCCESS,
        error: false,
        message: message
    };
};

export const profileUpdateUserDetailsFail = (message) => {
    return {
        type: actionTypes.PROFILE_UPDATE_USER_DETAILS_FAIL,
        error: true,
        message: message
    };
};

export const updateUserDetails = (body) => {
    return (dispatch) => {
        dispatch(profileUpdateUserDetails());
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/updateuserdetails', body, {
            headers: {
                authorization: `Bearer ` + localStorage.getItem("idToken").replace(/['"]+/g, '')
            }
        })
            .then((res) => {
                dispatch(profileUpdateUserDetailsSuccess("Profile updated successfully!"));
                dispatch(actions.getUserDetails());
            })
            .catch((message) => {
                dispatch(profileUpdateUserDetailsFail(message));
            })
    }
}