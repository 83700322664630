import * as actionTypes from "./actionTypes";
import axios from "axios";

export const authRegister = () => {
    return {
        type: actionTypes.AUTH_REGISTER,
        error: false
    };
};

export const authRegisterSuccess = (message) => {
    return {
        type: actionTypes.AUTH_REGISTER_SUCCESS,
        error: false,
        message: message
    };
};

export const authRegisterFail = (message) => {
    return {
        type: actionTypes.AUTH_REGISTER_FAIL,
        error: true,
        message: message
    };
};

export const register = (body) => {

    return (dispatch) => {
        dispatch(authRegister());
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/signup', {
            username: body.username,
            name: body.name,
            email: body.email,
            company: body.company,
            password: body.password,
            avatarappearance: body.avatarappearance,
            meeting:[],
            booths:[],
            rooms:[]
        })
            .then((res) => {
                dispatch(authRegisterSuccess("Your account has been created. Please check your email to verify your account."));
            })
            .catch((error) => {
                if (error.response.data.username) {
                    dispatch(authRegisterFail("Email already in use"));
                } else if (error.response.data.email) {
                    dispatch(authRegisterFail(error.response.data.email));
                } else {
                    dispatch(authRegisterFail(error.response.data.general));
                }
            })
    }
}