import * as actionTypes from "./actionTypes";
import axios from "axios";

export const profileChangeEmail = () => {
    return {
        type: actionTypes.PROFILE_CHANGE_EMAIL,
        error: false
    };
};

export const profileChangeEmailSuccess = (idToken, refreshToken, expirationTime, message) => {
    return {
        type: actionTypes.PROFILE_CHANGE_EMAIL_SUCCESS,
        idToken: idToken,
        refreshToken: refreshToken,
        expirationTime: expirationTime,
        error: false,
        message: message
    };
};

export const profileChangeEmailFail = (message) => {
    return {
        type: actionTypes.PROFILE_CHANGE_EMAIL_FAIL,
        error: true,
        message: message
    };
};

export const changeEmail = (email) => {
    console.log("changeEmail: " + email);
    return (dispatch) => {
        dispatch(profileChangeEmail());
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/changeprofileemail', {
            idToken: localStorage.getItem("idToken").replace(/['"]+/g, ''),
            email: email,
        })
            .then((res) => {
                if (res.data.res.idToken !== undefined) {
                    localStorage.setItem("idToken", JSON.stringify(res.data.res.idToken));
                    localStorage.setItem("refreshToken", JSON.stringify(res.data.res.refreshToken));

                    var expirationTime = Number(localStorage.getItem("expirationTime").replace(/['"]+/g, '')) + Number(res.data.res.expiresIn);
                    localStorage.setItem("expirationTime", JSON.stringify(expirationTime));

                    dispatch(profileChangeEmailSuccess(res.data.res.idToken, res.data.res.refreshToken, expirationTime, "Email changed successfully!"));
                } else {
                    dispatch(profileChangeEmailFail("Cannot use old email!"));
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERR: " + error.response.data.error);
                    dispatch(profileChangeEmailFail(error.response.data.error));
                }
            })
    }
}