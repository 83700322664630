import React, { Component } from "react";
import { connect } from "react-redux";

import cssClasses from "./ForgotPassword.module.css";
import Input from "../../../components/UI/Input/Input";
import HeaderLogo from "../../../components/HeaderLogo/HeaderLogo";
// import LoadingCube from "../../../components/UI/LoadingCube/LoadingCube";
import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import AuthError from "../AuthError/AuthError";
import AuthSuccess from "../AuthSuccess/AuthSuccess";

import ForgotPasswordActions from "./ForgotPasswordActions/ForgotPasswordActions";
import * as actions from "../../../store/actions/index";

class ForgotPassword extends Component {
  state = {
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          type: "email",
          placeholder: "",
          label: "Email",
        },
        value: "",
        validation: {
          required: true,
          isEmail: true,
        },
        valid: false,
        touched: false,
      },
    }
  };

  componentDidMount() {
    this.props.onResetError();
  }

  checkValidity(value, rules) {
    let isValid = true;

    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isEmail) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase());
    }

    if (rules.isConfirmPassword) {
      isValid = value === this.state.controls.password.value;
    }

    return isValid;
  }

  inputChangeHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true
      }
    }
    this.setState({ controls: updatedControls });
  }

  submitHandler = (event) => {
    event.preventDefault();
    if (this.state.controls.email.valid) {

      this.props.onForgotPassword(
        this.state.controls.email.value
      )

    } else if (!this.state.controls.email.valid) {
      this.props.onAuthError("Email is invalid");
    }
  }

  render() {

    if (localStorage.getItem("idToken") !== null) {
      this.props.history.push("./mainapp");
    }

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    let form = formElementsArray.map((formElement) => (
      <Input
        key={formElement.id}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={(event) => this.inputChangeHandler(event, formElement.id)}
        label={formElement.config.elementConfig.label}
      ></Input>
    ));

    let forgotPasswordForm = <form id="forgot-password-form" className={cssClasses.ForgotPassword} onSubmit={this.submitHandler}>
      <p
        style={{
          color: "#000000",
          opacity: "1",
          margin: " 0 0 1.68% 0",
          font: "normal normal bold 24px/28px Arial",
          alignSelf: "center",
        }}
      >
        Forgot Password
      </p>
      <p
        style={{
          color: "#000000",
          margin: " 0 0 1.98% 0",
          font: "normal normal normal 18px/21px Arial",
          alignSelf: "center",
        }}
      >
        Please provide registered email
      </p>
      {form}
      <ForgotPasswordActions />
    </form>;

    if (this.props.loading) {
      // forgotPasswordForm = <div className={cssClasses.ForgotPassword}><LoadingSpinner /></div>
      forgotPasswordForm = <LoadingSpinner />
    }

    let authErrorComponent = null;
    let authSuccessComponent = null;

    if (this.props.error) {
      authErrorComponent = <AuthError authError={this.props.message} />;
    }

    if (this.props.message && !this.props.error) {
      authSuccessComponent = <AuthSuccess authSuccess={this.props.message} />;
    }

    return (
      <div className={cssClasses.ForgotPasswordParent}>
        <HeaderLogo />
        {forgotPasswordForm}
        {authErrorComponent}
        {authSuccessComponent}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    message: state.auth.message
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onForgotPassword: (email) =>
      dispatch(actions.forgotPassword(email)),
    onAuthError: (error) =>
      dispatch(actions.authError(error)),
    onResetError: () =>
      dispatch(actions.authErrorReset())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
