import React, { Component } from "react";
import cssClasses from "./UpdateMeetingModal.module.css";
import Auxilliary from "../../../../hoc/Auxilliary/Auxilliary";
import Backdrop from "../../Backdrop/Backdrop";
import UpdateMeeting from "../../../SaasTool/UpdateMeeting/UpdateMeeting";

class UpdateMeetingModal extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
    }

    render() {
        return (
            <Auxilliary>
                <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
                <div
                    className={cssClasses.UpdateMeetingModal}
                    style={{
                        transform: this.props.show ? "translateY(0)" : "translateY(-100vh)",
                        opacity: this.props.show ? "1" : "0"
                    }}>
                    <UpdateMeeting meeting={this.props.meeting} />
                </div>
            </Auxilliary>
        );
    }
}
export default UpdateMeetingModal;