import React, { Component } from "react";

class Analytics extends Component {
    render() {
        return (
            <div>
                <h1>This is analytics page</h1>
            </div>);
    }
}

export default Analytics;