import * as actionTypes from "./actionTypes";
import axios from "axios";

export const authForgotPassword = () => {
    return {
        type: actionTypes.AUTH_FORGOT_PASSWORD,
        error: false
    };
};

export const authForgotPasswordSuccess = (message) => {
    return {
        type: actionTypes.AUTH_FORGOT_PASSWORD_SUCCESS,
        error: false,
        message: message
    };
};

export const authForgotPasswordFail = (message) => {
    return {
        type: actionTypes.AUTH_FORGOT_PASSWORD_FAIL,
        error: true,
        message: message
    };
};

export const forgotPassword = (email) => {

    return (dispatch) => {
        dispatch(authForgotPassword());
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/forgotpassword', {
            email: email
        })
            .then(() => {
                dispatch(authForgotPasswordSuccess("Please check your email for reset password link."));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERR: " + error.response.data.error);
                    dispatch(authForgotPasswordFail(error.response.data.error));
                }
            })
    }
}