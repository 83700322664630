import React from "react";
import voothLogo from "../../assets/images/Vooth_logo_white.png";
import cssClasses from "./Logo.module.css";

const logo = (props) => (
    <div className = {cssClasses.Logo}>
        <img src={voothLogo} alt="Vooth Logo"></img>
    </div>
);

export default logo;