import * as actionTypes from "./actionTypes";

export const authErrorSuccess = (message) => {
    return {
        type: actionTypes.AUTH_ERROR,
        error: true,
        message: message
    }
}

export const authError = (error) => {

    return (dispatch) => {
        dispatch(authErrorSuccess(error));
    }
}