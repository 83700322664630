import React, { Component } from "react";
import { connect } from "react-redux";
import MeetingComponent from "./MeetingComponent/MeetingComponent";

import cssClasses from "./Meetings.module.css";

import * as actions from "../../../store/actions/index";
import { withRouter } from "react-router-dom";

class Meetings extends Component {


    // componentDidMount() {

    //     // console.log("history : " + JSON.stringify(this.props.history));

    //     if (this.props.meetings) {
    //         if (Object.keys(this.props.meetings).length >= 0) {
    //             console.log("Meetings length : " + Object.keys(this.props.meetings).length);
    //             console.log("Meetings : " + this.props.meetings[0]);

    //         }
    //     }
    // }

    deleteMeeting = (index) => {
        if (window.confirm('Are you sure you wish to delete this meeting?')) {

            let meetingsArray = [];
            meetingsArray = this.props.meetings;

            console.log("meetingsArray.length: " + meetingsArray.length);

            let newMeetingsArray = [];

            meetingsArray.forEach(element => {
                if (element !== meetingsArray[index]) {
                    newMeetingsArray.push(element);
                }
            });

            console.log("newMeetingsArray.length: " + newMeetingsArray.length);

            let body = {
                meetings: newMeetingsArray
            }
            this.props.onUpdateUserDetails(body);
        }
    }

    render() {

        let meetings = <h1>No meetings scheduled yet.</h1>;

        console.log("Meetings: " + this.props.meetings);

        const meetingsArray = [];

        for (let key in this.props.meetings) {
            meetingsArray.push({
                id: key,
                data: this.props.meetings[key],
            });
        }

        meetings = meetingsArray.map((meeting, index) => (
            <MeetingComponent
                key={meeting.data.id}
                meetingIndex={index}
                meetingName={meeting.data.meetingName}
                subject={meeting.data.subject}
                date={meeting.data.date}
                virtualRoom={meeting.data.virtualRoom}
                hostMeetingURL={meeting.data.hostMeetingURL}
                visitorMeetingURL={meeting.data.visitorMeetingURL}
                hostAccessCode={meeting.data.hostAccessCode}
                visitorAccessCode={meeting.data.visitorAccessCode}
                participants={meeting.data.participants}
                meetingIndex={index}
                delete={() => this.deleteMeeting(index)}
            ></MeetingComponent>
        ));

        return (
            <div className={cssClasses.Meetings}>
                {meetings}
                {/* <UpdateMeeting /> */}
            </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        meetings: state.mainApp.userDetails.meetings
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUserDetails: (body) => {
            dispatch(actions.updateUserDetails(body))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Meetings));