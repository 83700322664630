import * as actionTypes from "./actionTypes";
import axios from "axios";

export const authVerifyPasswordReset = () => {
    return {
        type: actionTypes.AUTH_VERIFY_PASSWORD_RESET,
        error: false
    }
}

export const authVerifyPasswordResetSuccess = (message) => {
    return {
        type: actionTypes.AUTH_VERIFY_PASSWORD_RESET_SUCCESS,
        error: false,
        message: message
    }
}

export const authVerifyPasswordResetFail = (message) => {
    return {
        type: actionTypes.AUTH_VERIFY_PASSWORD_RESET_FAIL,
        error: true,
        message: message
    }
}

export const authSaveOobCode = (oobCode) => {
    return {
        type: actionTypes.AUTH_SAVE_OOBCODE,
        oobCode: oobCode,
        error: false
    }
}

export const verifyPasswordReset = (oobCode) => {
    console.log("OOBCODE : " + oobCode);
    return (dispatch) => {
        dispatch(authVerifyPasswordReset());
        axios.post('https://asia-east2-voothdevapi.cloudfunctions.net/api/verifypasswordreset', {
            oobCode: oobCode
        })
            .then((res) => {
                console.log("RES: " + res.data);
                dispatch(authVerifyPasswordResetSuccess());
                dispatch(authSaveOobCode(oobCode));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.error);
                    dispatch(authVerifyPasswordResetFail(error.response.data.error));
                }
            })
    }
}