import React, { Component } from "react";
import cssClasses from "./Meeting.module.css";

class Meeting extends Component {

    state = {
        accessCode: ""
    }

    componentDidMount() {
        if (this.state.accessCode.length > 0) {
            return;
        }

        const accessCode = new URLSearchParams(this.props.location.search).get("accessCode");

        this.setState({ accessCode: accessCode });

        window.addEventListener('message', this.interactionWithUnity);
    }

    interactionWithUnity = (event) => {
        console.log("Event data recieved in react Meeting component: " + event.data);

        switch (event.data) {
            case "Loaded": {

                const childFrameObj = document.getElementById('iframeMeeting');
                childFrameObj.contentWindow.postMessage(this.state.accessCode, '*'); //window.postMessage

                break;
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.interactionWithUnity);
    }

    render() {
        return (
            <div className={cssClasses.Meeting}>
                <iframe id="iframeMeeting" title="Meeting" src="https://meeting.d1vvumcoujhz3i.amplifyapp.com/" style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", margin: "0 0 0 0" }} frameBorder="0" scrolling="no" ></iframe>
            </div>
        );
    }
}
export default Meeting;