import React from "react";

import cssClasses from "./ForgotPasswordActions.module.css";
import Button from "../../../../components/UI/Button/Button";

const forgotPasswordActions = (props) => {
  return (
    <div className={cssClasses.ForgotPasswordActions}>
      <Button type ="submit" form = "forgot-password-form">Send</Button>
    </div>
  );
};

export default forgotPasswordActions;
