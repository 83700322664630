import * as actionTypes from "./actionTypes";
import axios from "axios";

export const authGetUserDetails = () => {
    return {
        type: actionTypes.GET_USER_DETAILS,
        error: false
    }
}
export const authGetUserDetailsSuccess = (userDetails) => {
    return {
        type: actionTypes.GET_USER_DETAILS_SUCCESS,
        userDetails: userDetails,
        error: false
    }
}
export const authGetUserDetailsFail = (message) => {
    return {
        type: actionTypes.GET_USER_DETAILS_FAIL,
        userDetails: null,
        error: true
    }
}

export const getUserDetails = () => {
    return (dispatch) => {
        dispatch(authGetUserDetails());
        axios.get('https://asia-east2-voothdevapi.cloudfunctions.net/api/getuserdetails', {
            headers: {
                authorization: `Bearer ` + localStorage.getItem("idToken").replace(/['"]+/g, '')
            }
        })
            .then((res) => {
                console.log("userCredentials: " + res.data.userCredentials);
                dispatch(authGetUserDetailsSuccess(res.data.userCredentials));
            })
            .catch((error) => {
                if (error.response) {
                    console.log("ERROR: " + error.response.data.error);
                    dispatch(authGetUserDetailsFail(error.response.data.error));
                }
            })
    }
};