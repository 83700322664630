import React, { Component } from "react";

import cssClasses from "./HeaderName.module.css";

class HeaderName extends Component {
    render() {
        return (<div className={cssClasses.HeaderName}>
            <p>{this.props.saasToolName}</p>
        </div>)
    }
}

export default HeaderName;