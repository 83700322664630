import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  token: null,
  refreshToken: null,
  expirationTime: 0,
  error: false,
  message: null,
  loading: false,
  loginSuccessfull: false,
  registerSuccessfull: false,
  forgotPasswordSuccessfull: false,
  verifyPasswordSuccessfull: false,
  changePasswordSuccessfull: false,
  oobCode: null,
  emailUpdating: false,
  passwordUpdating: false,
  passwordUpdateRequest: false,
  userDetailsUpdating: false
};

const authLogin = (state, action) => {
  return updateObject(state, { error: false, loading: true });
};

const authLoginSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    refreshToken: action.refreshToken,
    expirationTime: localStorage.getItem("expirationTime"),
    error: false,
    message: action.message,
    loading: false,
    loginSuccessfull: true
  });
};

const authLoginFail = (state, action) => {
  return updateObject(state, {
    error: true,
    message: action.message,
    loading: false,
    loginSuccessfull: false
  });
};

const authRegister = (state, action) => {
  return updateObject(state, { error: false, loading: true });
};

const authRegisterSuccess = (state, action) => {
  return updateObject(state, {
    error: false,
    message: action.message,
    loading: false,
    registerSuccessfull: true
  });
};

const authRegisterFail = (state, action) => {
  return updateObject(state, {
    error: true,
    message: action.message,
    loading: false,
    registerSuccessfull: false
  });
};

const authForgotPassword = (state, action) => {
  return updateObject(state, { error: false, loading: true });
};

const authForgotPasswordSuccess = (state, action) => {
  return updateObject(state, {
    error: false,
    message: action.message,
    loading: false,
    forgotPasswordSuccessfull: true
  });
};

const authForgotPasswordFail = (state, action) => {
  return updateObject(state, {
    error: true,
    message: action.message,
    loading: false,
    forgotPasswordSuccessfull: false
  });
};

const authVerifyPasswordReset = (state, action) => {
  return updateObject(state, { error: false, loading: true });
};

const authVerifyPasswordResetSuccess = (state, action) => {
  return updateObject(state, {
    error: false,
    message: action.message,
    loading: false,
    verifyPasswordSuccessfull: true,
    oobCode: action.oobCode
  });
};

const authVerifyPasswordResetFail = (state, action) => {
  return updateObject(state, {
    error: true,
    message: action.message,
    loading: false,
    verifyPasswordSuccessfull: false,
  });
};

const authSaveOobCode = (state, action) => {
  return updateObject(state, {
    error: false,
    loading: false,
    oobCode: action.oobCode
  });
};

const authChangePassword = (state, action) => {
  return updateObject(state, { error: false, loading: true });
};

const authChangePasswordSuccess = (state, action) => {
  return updateObject(state, {
    error: false,
    message: action.message,
    loading: false,
    changePasswordSuccessfull: true
  });
};

const authChangePasswordFail = (state, action) => {
  return updateObject(state, {
    error: true,
    message: action.message,
    loading: false,
    changePasswordSuccessfull: false
  });
};
const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    error: false,
    message: action.message,
    loading: false,
    loginSuccessfull: false
  });
};

const authErrorSuccess = (state, action) => {
  return updateObject(state, {
    error: true,
    message: action.message,
    loading: false,
  });
};

const authErrorReset = (state, action) => {
  return updateObject(state, {
    error: false,
    loading: false,
    message:null
  });
};

const profileChangeEmail = (state, action) => {
  return updateObject(state, { emailUpdating: true, error: false });
};

const profileChangeEmailSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    refreshToken: action.refreshToken,
    expirationTime: action.expirationTime,
    emailUpdating: false,
    error: false,
    message: action.message,
  });
};

const profileChangeEmailFail = (state, action) => {
  return updateObject(state, {
    emailUpdating: false,
    error: true,
    message: action.message,
  });
};

const profileChangePassword = (state, action) => {
  return updateObject(state, { passwordUpdating: true, error: false });
};

const profileChangePasswordUpdateRequest = (state, action) => {
  return updateObject(state, { passwordUpdateRequest: true, error: false });
};

const profileChangePasswordSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    refreshToken: action.refreshToken,
    expirationTime: action.expirationTime,
    passwordUpdating: false,
    passwordUpdateRequest: false,
    error: false,
    message: action.message
  });
};

const profileChangePasswordFail = (state, action) => {
  return updateObject(state, {
    passwordUpdating: false,
    passwordUpdateRequest: false,
    error: true,
    message: action.message
  });
};

const profileUpdateUserDetails = (state, action) => {
  return updateObject(state, { userDetailsUpdating: true, error: false,loading:true });
};

const profileUpdateUserDetailsSuccess = (state, action) => {
  return updateObject(state, {
    userDetailsUpdating: false,
    error: false,
    message: action.message,
    loading:false
  });
};

const profileUpdateUserDetailsFail = (state, action) => {
  return updateObject(state, {
    userDetailsUpdating: false,
    error: true,
    message: action.message,
    loading:false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_LOGIN:
      return authLogin(state, action);

    case actionTypes.AUTH_LOGIN_SUCCESS:
      return authLoginSuccess(state, action);

    case actionTypes.AUTH_LOGIN_FAIL:
      return authLoginFail(state, action);

    case actionTypes.AUTH_REGISTER:
      return authRegister(state, action);

    case actionTypes.AUTH_REGISTER_SUCCESS:
      return authRegisterSuccess(state, action);

    case actionTypes.AUTH_REGISTER_FAIL:
      return authRegisterFail(state, action);

    case actionTypes.AUTH_FORGOT_PASSWORD:
      return authForgotPassword(state, action);

    case actionTypes.AUTH_FORGOT_PASSWORD_SUCCESS:
      return authForgotPasswordSuccess(state, action);

    case actionTypes.AUTH_FORGOT_PASSWORD_FAIL:
      return authForgotPasswordFail(state, action);

    case actionTypes.AUTH_VERIFY_PASSWORD_RESET:
      return authVerifyPasswordReset(state, action);

    case actionTypes.AUTH_VERIFY_PASSWORD_RESET_SUCCESS:
      return authVerifyPasswordResetSuccess(state, action);

    case actionTypes.AUTH_VERIFY_PASSWORD_RESET_FAIL:
      return authVerifyPasswordResetFail(state, action);

    case actionTypes.AUTH_SAVE_OOBCODE:
      return authSaveOobCode(state, action);

    case actionTypes.AUTH_CHANGE_PASSWORD:
      return authChangePassword(state, action);

    case actionTypes.AUTH_CHANGE_PASSWORD_SUCCESS:
      return authChangePasswordSuccess(state, action);

    case actionTypes.AUTH_CHANGE_PASSWORD_FAIL:
      return authChangePasswordFail(state, action);

    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);

    case actionTypes.AUTH_ERROR:
      return authErrorSuccess(state, action);

    case actionTypes.AUTH_ERROR_RESET:
      return authErrorReset(state, action);

    case actionTypes.PROFILE_CHANGE_EMAIL:
      return profileChangeEmail(state, action);

    case actionTypes.PROFILE_CHANGE_EMAIL_SUCCESS:
      return profileChangeEmailSuccess(state, action);

    case actionTypes.PROFILE_CHANGE_EMAIL_FAIL:
      return profileChangeEmailFail(state, action);

    case actionTypes.PROFILE_CHANGE_PASSWORD:
      return profileChangePassword(state, action);

    case actionTypes.PROFILE_CHANGE_PASSWORD_UPDATE_REQUEST:
      return profileChangePasswordUpdateRequest(state, action);

    case actionTypes.PROFILE_CHANGE_PASSWORD_SUCCESS:
      return profileChangePasswordSuccess(state, action);

    case actionTypes.PROFILE_CHANGE_PASSWORD_FAIL:
      return profileChangePasswordFail(state, action);

    case actionTypes.PROFILE_UPDATE_USER_DETAILS:
      return profileUpdateUserDetails(state, action);

    case actionTypes.PROFILE_UPDATE_USER_DETAILS_SUCCESS:
      return profileUpdateUserDetailsSuccess(state, action);

    case actionTypes.PROFILE_UPDATE_USER_DETAILS_SUCCESS:
      return profileUpdateUserDetailsFail(state, action);

    default:
      return state;
  }
};

export default reducer;