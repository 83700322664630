import React, { Component } from "react";
import cssClasses from "./Layout.module.css";

class Layout extends Component {
  render() {
    return (
      <div className={cssClasses.Layout}>
        {/* <main className={cssClasses.Content}>{this.props.children}</main> */}
        {this.props.children}
      </div>
    );
  }
}

export default Layout;