import React, { Component } from "react";

import cssClasses from "./ErrorComponent.module.css";

class ErrorComponent extends Component {
    render() {
        return (<div className={cssClasses.errorComponent}>
            <h1>This is an error component</h1>
        </div>);
    }
}

export default ErrorComponent;