import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as actions from "../../../store/actions/index";

import cssClasses from "./Profile.module.css";

class Profile extends Component {

    state = {
        isHovering: false
    }

    handleMouseHoverEnter = () => {
        this.setState({ isHovering: true });
    }

    handleMouseHoverLeave = () => {
        this.setState({ isHovering: false });
    }

    profileHandler = () => {
        this.props.history.push("./profile");
    }

    logoutHandler = () => {
        this.props.onLogout();
        this.props.history.push("./login");
    };

    render() {
        let profileAction = <div style={{ width: "26.5%", height: "146.5%", float: "right", display: "flex", justifyContent: "center", alignItems: "center" }} onMouseEnter={this.handleMouseHoverEnter} onMouseLeave={this.handleMouseHoverLeave} >
            {!this.state.isHovering ? <div style={{ width: "100%", height: "100%", margin: "0 0 0 0", display: "flex", justifyContent: "center" }}>
                <p style={{ margin: "10% 0 0 0", width: "fit-content", height: "13.82%", font: "normal bold 16px/18px Arial" }}>{this.props.name}</p>
            </div> :
                <div style={{ width: "100%", height: "100%", margin: "0 0 0 0", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <p style={{ margin: "10% 0 0 0", width: "fit-content", height: "13.82%", font: "normal bold 16px/18px Arial" }}>{this.props.name}</p>
                    <div style={{ margin: "11.7% 0 0 0 ", width: "100%", height: "78%", zIndex: "1", backgroundColor: "#EEEEEE", display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <button className={cssClasses.ProfileActionButton} onClick={this.profileHandler}>Profile</button>
                        <hr style={{ width: "92.15%", height: "1.5px", backgroundColor: "#BDBDBD", border: "none", margin: "0 0 0 0 " }} />
                        <button className={cssClasses.ProfileActionButton} onClick={this.logoutHandler}>Logout</button>
                    </div>
                </div>
            }
        </div>
        return (
            <div className={cssClasses.Profile}>
                {profileAction}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () =>
            dispatch(actions.logout()),
    };
};

export default connect(null, mapDispatchToProps)(withRouter(Profile));