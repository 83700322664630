import React from "react";
import cssClasses from "./NavigationItem.module.css";
import BoothCreatorIcon from "../../../../assets/images/booth_creator_icon.png";
import RoomCreatorIcon from "../../../../assets/images/room_creator_icon.png";
import MeetingsIcon from "../../../../assets/images/meetings_icon.png";
import AnalyticsIcon from "../../../../assets/images/analytics_icon.png";
import WhiteSquareIcon from "../../../../assets/images/white-square.png";

const navigationItem = (props) => {

    let icon = "";

    switch (props.name) {
        case "Booth Creator": {
            icon = BoothCreatorIcon;
            break;
        }
        case "Room Creator": {
            icon = RoomCreatorIcon;
            break;
        }
        case "Meetings": {
            icon = MeetingsIcon;
            break;
        }
        case "Analytics": {
            icon = AnalyticsIcon;
            break;
        }
        default: {
            icon = WhiteSquareIcon;
            break;
        }
    }

    return (
        <li className={cssClasses.NavigationItem} >
            <img src={icon} alt={props.name} ></img>
            <p onClick={props.clicked}>{props.name}</p>
        </li>
    )
}

export default navigationItem;